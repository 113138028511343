import React, { useState,Fragment } from 'react';
import { Menu,  Transition } from '@headlessui/react'
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi';
import { FaUser } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
// import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './settings.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";




function Settings(){
    const navigate = useNavigate()
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings',backgroundColor:'black',textColor:'#896835' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout' }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
    return(
          <>
        <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
        <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
          <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
          
      
        
        <div className="flex items-center gap-2 ml-auto md:ml-650 justify-end"> 
        
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
              <span className="sr-only">Open user menu</span>
              <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => navigate('/profile')}
                    className={classNames(
                      active && 'bg-gray-100',
                      'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                    )}
                  >
                    Your Profile
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => navigate('/settings')}
                    className={classNames(
                      active && 'bg-gray-100',
                      'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                    )}
                  >
                    Settings
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active && 'bg-gray-100',
                      'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                    )}
                  >
                    Sign out
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div></div></div>
                    <div className='w-screen h-screen flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/6':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                          <div className=" p-0 flex justify-end">
                              <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                          </div>
                          <div className='mt-4 flex flex-col gap-4 relative'>
              {filteredMenus?.map((menu, i) => (
                  <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                      <div>
                          {React.createElement(menu?.icon, { size: "20" })}
                      </div>
                      <div className="flex flex-col">
                          <h2
                              style={{ transitionDelay: `${i + 3}00ms` }}
                              className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                          <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                           overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                      </div>
                  </Link>
              ))}
              </div></div>
      
              <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'} md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                          <div className=" p-0 flex justify-end">
                              <HiMenuAlt3 size={26} className="cursor-pointer" onClick={()=>setOpen(!open)}/>
                          </div>
                          <div className='mt-4 flex flex-col gap-4 relative'>
              {filteredMenus?.map((menu, i) => (
                  <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor}}>
                      <div>
                          {React.createElement(menu?.icon, { size: "20" })}
                      </div>
                      <div className="flex flex-col">
                          {/* <h2
                              style={{ transitionDelay: `${i + 3}00ms` }}
                              className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                          <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                           overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                     */}
                 </div>   </Link>
              ))}
              </div></div>
      



              {/* <div className="flex flex-col w-full">
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/materialgroup" className="btn-link">Group</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/material" className="btn-link">Material</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/materialctgy1" className="btn-link">Category</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/materialctgy2" className="btn-link">Sub Category</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/uom" className="btn-link">UoM</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/mstandard" className="btn-link">Measurement Standard</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/country" className="btn-link">Country</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/state" className="btn-link">State</Link>
  </div>
  <div className="flex flex-col bg-[#ededed] text-lightgolden w-full">
    <Link to="/location" className="btn-link">Location</Link>
  </div>
</div> */}
<div className="justify-center mt-10 h-screen">
    <div className="text-sm text-gray-900  w-auto mx-5 max-w-sm md:max-w-full">
        <Link to='/materialgroup'>
            <button className="text-sm p-1  mx-1 md:ml-210 w-full md:w-1/3 h-10 text-center shadow rounded-lg  shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">MATERIAL GROUP</button>
        </Link>
        <Link to='/material'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3  h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">MATERIAL</button>
        </Link>
        <Link to='/materialctgy1'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold bg-white hover:text-white hover:bg-lightgolden">MATERIAL CATEGORY I</button>
        </Link>
        <Link to='/materialctgy2'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">MATERIAL CATEGORY II</button>
        </Link>
        <Link to='/uom'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold bg-white hover:text-white hover:bg-lightgolden">UOM</button>
        </Link>
        <Link to='/mstandard'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 rounded-lg text-center shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">MEASUREMENT STANDARD</button>
        </Link>
        <Link to='/country'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 rounded-lg text-center shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">COUNTRY</button>
        </Link>
        <Link to='/state'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">STATE</button>
        </Link>
        <Link to='/location'>
            <button className="text-sm p-1 mx-1 md:ml-210 w-full md:w-1/3 h-10 mt-2 text-center rounded-lg shadow shadow-black text-lightgolden font-bold  bg-white hover:text-white hover:bg-lightgolden">LOCATION</button>
        </Link>
    </div>
</div>
        
                
                  

                   
                    
                
            </div>
        </>
    )
}
export default Settings;