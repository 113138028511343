import React, { useState, useEffect, useRef } from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";
// import axios from 'axios';
// import { data } from 'autoprefixer';
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function EditUom({ onClose, uomId }) {
  const modalRef = useRef();
 const [uoms,setUoms]=useState([]);

  const [formData, setFormData] = useState({
    name: '',
    material_group_id: '',
    created_by: '1'
  });
  const [serverResponse, setServerResponse] = useState(null);

  useEffect(() => {
    
    fetch('https://genexers.online/genexers/masters/listuoms') // Fetching material groups data from another API
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setUoms(data.data);
            } else {
                console.error('Error fetching Uoms:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching Uoms:', error);
        });
}, []);
useEffect(() => {
    const uom = uoms.find(uom => uomId === uom.id);
    if (uom) {
      setFormData(prevState => ({
        ...prevState,
        name: uom.name,
        created_by: uom.created_by
        // Populate other form fields if any
      }));
    }
  }, [uomId, uoms]);

const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true)
          // Make a POST request to the server with form data
          const response = await fetch(`https://genexers.online/genexers/masters/uom/${uomId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(formData),
          });
      
          // Parse the response JSON
          const responseData = await response.json();
      
      //     // Update state with the server response
          setServerResponse(responseData);
          if (response.ok) {
            window.location.href = '/uom';
          } else {
            // Handle server errors or other unsuccessful responses here
            console.error('Server error:', responseData);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }finally{
          setLoading(false)
        }
      }
      
        const handleChange = (e) => {
          // const { name, value } = e.target;
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        };
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  }
  const [loading,setLoading]=useState(false);
  
  return (
    <>
    {loading && <SpinnerPopup />}
    <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Measurement Standard</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
    <div className="flex flex-col md:flex-row gap-4 text-sm">
              <input
                type="text"
                placeholder='Uom'
                name='name'
                value={formData.name}
                onChange={handleChange}
                className='w-full px-2 py-1   rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200'
                required="required"
              />
              
          
        </div>
       
          
            <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
      </form>
      {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
    </div>
  </div>


 
       </>
  );
}

export default EditUom;