import React, { useEffect,useState,useRef } from 'react';
// import axios from 'axios';
// import { AiOutlineClose } from "react-icons/ai";
import { IoMdCloseCircleOutline } from "react-icons/io";
import './vendors.css'
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}




function EditCountry({onClose,countryId}){
  console.log("id is "+countryId);
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}
const [formData, setFormData] = useState({
  name:'',
  created_by:''
  // Other form fields if any
}); 
const [countries, setCountries] = useState([]);

useEffect(() => {
  console.log(countryId);
  fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
}, [countryId]);  

useEffect(() => {
  const country = countries.find(group => countryId === group.id);
  if (country) {
    setFormData(prevState => ({
      ...prevState,
      name: country.name,
      id:country.id,
      created_by: country.created_by
      // Populate other form fields if any
    }));
  }
}, [countryId, countries]);
const [serverResponse, setServerResponse] = useState(null);
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    setLoading(true)
    const response = await fetch(`https://genexers.online/genexers/masters/editcountry/${countryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData), // Correctly stringify the form data
    });

    const responseData = await response.json();
    setServerResponse(responseData);
    

    if (response.ok) {
      window.location.href = '/country';
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
  }finally{
    setLoading(false)
  }
}


  const handleChange = (e) => {
    // const { name, value } = e.target;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [loading,setLoading]=useState(false);
    return(       
      <>
{loading && <SpinnerPopup />}
<div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Country</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="text" placeholder='Country' value={formData.name} name='name'  className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required"  onChange={handleChange} />
                    </div>
                   
                   
                    <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
                   </form>
                   {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}</div>




                   
                   
                   
                   </div>
</>



                  

                   
                
           
        
    )
}
export default EditCountry;