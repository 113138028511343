import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { AiOutlineClose } from "react-icons/ai";
// import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function AddState({ onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    
    created_by: '1'
});

  const modalRef = useRef();
  const [countries, setCountries] = useState([]);
  const [selectedcountry,setSelectedCountry] = useState('');

  useEffect(() => {
    fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setCountries(data.data); // Assuming data.data contains an array of countries
        } else {
          console.error('Error fetching countries:', data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, []);
  const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};
const handleCountryChange = (event) => {
  const selectedCountry = event.target.value;
  setSelectedCountry(selectedCountry);
  setFormData({ ...formData, country_id: selectedCountry });
 
  

};
const [serverResponse, setServerResponse] = useState(null);
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    setLoading(true)
    // Make a POST request to the server with form data
    const response = await fetch('https://genexers.online/genexers/masters/addstate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(formData),
  });

  // Parse the response JSON
  const responseData = await response.json();

//     // Update state with the server response
  setServerResponse(responseData);
  if (response.ok) {
    window.alert("Submitted Successfully");
      setTimeout(() => {
          window.location.href = '/state';
        }, 500);
  } else {
    // Handle server errors or other unsuccessful responses here
    console.error('Server error:', responseData);
    window.alert('Submission Failed...Please try again');
  }
} catch (error) {
  console.error('Error submitting form:', error);
  window.alert('An error occurred. Please try again later.');
}finally{
  setLoading(false)
}
}

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };
  const [loading,setLoading]=useState(false);
  return (
    <>
    {loading && <SpinnerPopup />}
      <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add State</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
            <select name='country_id' value={selectedcountry} className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedcountry ? 'black' : 'gray'}}  required="required" onChange={handleCountryChange}>
            <option value="">Select Country</option>
        {countries.map(country => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
          ))}
            </select>
          
              <input type="text" placeholder='State' name='name' className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleChange}/>
             
          </div>
          
          <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
        </form>
        {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
      </div>
    </div>
    </>
  );
}

export default AddState;
