import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function EditMaterialCategory1({ onClose, categoryId }) {
    const [formData, setFormData] = useState({
        name: '',
        material_id: '',
        created_by: '1'
    });
   

    const modalRef= useRef();
    const [serverResponse, setServerResponse] = useState(null);
    
    const [materials, setMaterials] = useState([]);
    
    const [materialcategories, setMaterialcategory1] = useState([]);

    useEffect(() => {
        const fetchMaterials = async () => {
            try {
                const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: null });
                if (response.data.status === 'success') {
                    setMaterials(response.data.data);
                    console.log(response.data.data);
                } else {
                    throw new Error(response.data.message || 'Error fetching materials');
                }
            } catch (error) {
                console.error('Error fetching materials:', error);
               
            } 
        };
    
        fetchMaterials();
    
        const fetchMaterialCategories = async () => {
            try {
                const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: null });
                if (response.data.status === 'success') {
                    setMaterialcategory1(response.data.data);
                    console.log(response.data.data);
                } else {
                    throw new Error(response.data.message || 'Error fetching materials');
                }
            } catch (error) {
                console.error('Error fetching materials:', error);
               
            } 
        };
    
        fetchMaterialCategories();
    }, []);
    useEffect(() => {
        const category = materialcategories.find(group => categoryId === group.id);
        if (category) {
          setFormData(prevState => ({
            ...prevState,
            name: category.name,
            material_id:category.material_id,
            created_by: category.created_by
            // Populate other form fields if any
          }));
        }
      }, [categoryId, materialcategories]);
    
    
     

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const closeModal= (e) => {
        if (modalRef.current === e.target) {
          onClose();
        }}
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const response = await fetch(`https://genexers.online/genexers/masters/editmaterialcategory/${categoryId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(formData),
            });

            const responseData = await response.json();
            setServerResponse(responseData);
            if (response.ok) {
                window.location.href = '/materialctgy1';
            } else {
                console.error('Server error:', responseData);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }finally{
            setLoading(false)
        }
    };
    const [loading,setLoading]=useState(false);
    return (
        <>
               {loading && <SpinnerPopup />}
    
               <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Category</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">

                            <select name='material_id' value={formData.material_id} className='w-full px-2 text-lg py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required" onChange={handleChange}>
                                <option value="">Material</option>
                                {materials.map(material => (
                                    <option key={material.id} value={material.id}>
                                        {material.name}
                                    </option>
                                ))}
                            </select>
                        
                                <input type="text" placeholder='Material Category' name='name' value={formData.name} className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleChange} />
                               
                        </div>
                   
                        <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>

                    
                </form>
                {serverResponse && <p style={{ color: 'black' }}>{serverResponse.message}</p>}
            </div>
        </div>
        </>
    );
}

export default EditMaterialCategory1;