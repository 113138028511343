import React, {useEffect, useRef, useState } from 'react';
import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'

import './products.css'
// import { AiOutlineClose } from "react-icons/ai";
// import {HiMenuAlt3} from 'react-icons/hi'
// import { MdOutlineDashboard } from "react-icons/md";
// import { FaRegUser } from "react-icons/fa";
// import { GrLogout } from "react-icons/gr";
// import { FiSettings } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function AddProducts({onClose}){
  const [loading,setLoading]=useState(false);
  const [states,setStates]=useState([]);
  const [locations,setLocations]=useState([]);
  const [selectedcountry,setSelectedCountry]=useState('');
  const [selectedstate,setSelectedState]=useState('');
  const [selectedlocation,setSelectedLocation]=useState('');
  const [filteredstate,setFilteredStates]=useState([]);
  const [filteredlocation,setFilteredLocations]=useState([]);
  const [countries,setCountries]=useState([]);
  const [materialGroups, setMaterialGroups] = useState([]);
      const [material, setMaterials] = useState([]);
      const [selectedmaterial, setSelectedMaterials] = useState('');
      const [materialcategory, setMaterialCategory] = useState([]);
      const [materialsubcategory, setMaterialSubCategory] = useState([]);
      const [selectedMaterialGroup, setSelectedMaterialGroup] = useState('');
      const [selectedmaterialcategory, setSelectedMaterialCategory] = useState('');
      const [selectedmaterialsubcategory,setSelectedSubCategory] = useState('');
  useEffect(() => {
    // Fetch material groups data
    fetch('https://genexers.online/genexers/masters/materialgroups')
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setMaterialGroups(data.data);
            } else {
                console.error('Error fetching material groups:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching material groups:', error);
        });
}, []);

  
  useEffect(() => {
  
  
    fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setStates(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLocations(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
  }, []);

  const [formData, setFormData] = useState({
    product_name: '',
    product_description: '',
    created_by: '1'
  });
  const [image, setImage] = useState('');

  // Create state for the file
  const handleMaterialGroupChange = (event) => {
    const selectedGroup = event.target.value;
    setSelectedMaterialGroup(selectedGroup);
    setFormData({ ...formData, material_group_id: selectedGroup });
    fetchMaterials(selectedGroup);
};
const handleMaterialChange = (event) => {
  const selectedMaterial = event.target.value;
  setSelectedMaterials(selectedMaterial);
  setFormData({ ...formData, material_id: selectedMaterial });
  fetchMaterialCategories(selectedMaterial);
};
const handleMaterialCategoryChange = (event) => {
  const selectedMaterialCategory = event.target.value;
  setSelectedMaterialCategory(selectedMaterialCategory);
  setFormData({ ...formData, material_category_id: selectedMaterialCategory });
  
  fetchMaterialSubCategories(selectedMaterialCategory);
};
const handleSubCategoryChange = (event) => {
  const selectedsubcategory = event.target.value;
  setSelectedSubCategory(selectedsubcategory);
  setFormData({ ...formData, material_sub_category_id: selectedsubcategory });
  console.log("inside subcategory");
  console.log(formData);
  
  

};

  

  // Update formData when other inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("inside handlechange");
    console.log(formData);
  };
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setFormData({ ...formData, country_id: selectedCountry });
    console.log("inside country");
    console.log(formData);
    fetchStates(selectedCountry);
    

};

const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setFormData({ ...formData, state_id: selectedState });
    console.log("inside states");
    console.log(formData);
    fetchLocations(selectedState);
   
};
const handleLocationChange = (event) => {
  const selectedlocation = event.target.value;
  setSelectedLocation(selectedlocation);
  setFormData({ ...formData, location_id: selectedlocation});
  console.log("inside locations");
  console.log(formData);
  
 
};
const fetchMaterials = async (selectedGroup) => {
  try {
      const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: selectedGroup });
      if (response.data.status === 'success') {
          setMaterials(response.data.data);
      } else {
          throw new Error(response.data.message || 'Error fetching materials');
      }
  } catch (error) {
      console.error('Error fetching materials:', error);
  }
};
const fetchMaterialCategories = async (selectedMaterial) => {
try {
    const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: selectedMaterial });
    if (response.data.status === 'success') {
        setMaterialCategory(response.data.data);
    } else {
        throw new Error(response.data.message || 'Error fetching materials');
    }
} catch (error) {
    console.error('Error fetching materials:', error);
}
};

const fetchMaterialSubCategories = async (selectedMaterialCategory) => {
try {
    const response = await axios.post('https://genexers.online/genexers/masters/materialsubcategories', { type_id: selectedMaterialCategory });
    if (response.data.status === 'success') {
        setMaterialSubCategory(response.data.data);
    } else {
        throw new Error(response.data.message || 'Error fetching materials');
    }
} catch (error) {
    console.error('Error fetching materials:', error);
}
};

const fetchStates = (selectedCountry) => {
    const filteredStates = states.filter(state => state.country_id === parseInt(selectedCountry));
    setFilteredStates(filteredStates);
};



const fetchLocations = (selectedState) => {
    const filteredLocations = locations.filter(location => location.state_id === parseInt(selectedState));
    setFilteredLocations(filteredLocations);
};
const handleImage=async(e)=>{
  const file = e.target.files[0];

  if (file) {
    const imageData = new FormData();
    imageData.append('document', file);

    fetch('https://genexers.online/genexers/uploadfile/upload', {
      method: 'POST',
      body: imageData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Handle successful response from the backend
      setImage(data.documentpath)
      console.log('Response from backend:', data);
      // Handle the response data here, if needed
    })
    .catch(error => {
      // Handle error
      console.error('Error sending file to backend:', error);
    });
  }
  setFormData({ ...formData, product_image:image});
  console.log("At submit");
  console.log(formData);
}
const handleSubmit = async (e) => {
  e.preventDefault();
    
  try {
    setLoading(true)
    const response = await fetch('https://genexers.online/genexers/inventory/addproduct', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData), // Correctly stringify the form data
    });

    const responseData = await response.json();
  
    

    if (response.ok) {
      window.alert("Submitted Successfully");
      setTimeout(() => {
          window.location.href = '/products';
        }, 500);
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
      window.alert('Submission Failed...Please try again');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    window.alert('An error occurred. Please try again later.');
  }finally{
    setLoading(false)}
}

const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}



          

          

    return(  
      <>     
      {loading && <SpinnerPopup/>}
                  <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-xs md:max-w-full bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add Product</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="text" placeholder='Name' name='product_name'   className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required='required' onChange={handleChange} />
                      <input  type="tel" placeholder='Description' name='product_description'   className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required='required' onChange={handleChange}   />
                    </div>

                    <div className="flex flex-row gap-4 text-sm">
    <select value={selectedMaterialGroup}   onChange={handleMaterialGroupChange} name='material_group_id' className="w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedMaterialGroup ? 'black' : 'gray' }} >
                            <option value="">Group</option>
                            {materialGroups.map(materialgroup => (
                                <option key={materialgroup.id} value={materialgroup.id} >
                                    {materialgroup.name}
                                </option>
                            ))}
                        </select>
    <select value={selectedmaterial} onChange={handleMaterialChange} name='material_id' className="w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmaterial ? 'black' : 'gray' }} >
                            <option value="">Material</option>
                                                        {material.map(material => (
                                <option key={material.id} value={material.id} >
                                    {material.name}
                                </option>
                            ))}
                        </select></div>

                        <div className="flex flex-row gap-4 text-sm">
    <select  value={selectedmaterialcategory} onChange={handleMaterialCategoryChange}  name='material_category_id' className="w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmaterialcategory ? 'black' : 'gray'}} >
                            <option value="">Material Category</option>
                            {materialcategory.map(materialcategory => (
                                <option key={materialcategory.id} value={materialcategory.id} >
                                    {materialcategory.name}
                                </option>
                            ))}
                        </select>
    <select  onChange={handleSubCategoryChange} value={selectedmaterialsubcategory}  name='material_sub_category_id' className="w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmaterialsubcategory ? 'black' : 'gray'}}  >
                            <option value="">Sub Category</option>
                            {materialsubcategory.map(materialsubcategory => (
                                <option key={materialsubcategory.id} value={materialsubcategory.id} >
                                    {materialsubcategory.name}
                                </option>
                            ))}
                        </select></div>


                                          
                        <div className="flex flex-row gap-4 text-sm">
                    <select onChange={handleCountryChange} value={selectedcountry} name='country_id' className="w-1/3  px-1 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required"  >
                            <option value="">Country</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.id} >
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    <select  onChange={handleStateChange} value={selectedstate} name='state_id' className="w-1/3  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required"  >
                            <option value="">State</option>
                            
                            {filteredstate.map(state => (
                                <option key={state.id} value={state.id} >
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    <select   name='location_id' value={selectedlocation} className="w-1/3  px-1 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleLocationChange}   >
                            <option value="">Location</option>
                            {filteredlocation.map(location => (
                                <option key={location.id} value={location.id} >
                                    {location.name}
                                </option>
                            ))}

                        </select></div>
                        <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="file" onChange={handleImage} placeholder='' name='product_image' accept="image/*"   className="w-full  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required='required'  />
                      
                     </div>   
                     <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
                   </form></div>




                   
                   
                   
                  </div>

</>


                  

                   
                
           
        
    )
}
export default AddProducts;