import React, { useState, useEffect, useRef } from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";
import axios from 'axios';
// import { data } from 'autoprefixer';
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function EditMaterial({ onClose, materialId }) {
  const modalRef = useRef();
  const [materialgroups, setMaterialGroups] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    material_group_id: '',
    created_by: '1'
  });
  const [serverResponse, setServerResponse] = useState(null);

  useEffect(() => {
    const fetchMaterials = async () => {
        try {
            const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: null });
            if (response.data.status === 'success') {
                setMaterials(response.data.data);
                console.log(response.data.data);
            } else {
                throw new Error(response.data.message || 'Error fetching materials');
            }
        } catch (error) {
            console.error('Error fetching materials:', error);
           
        } 
    };

    fetchMaterials();

    fetch('https://genexers.online/genexers/masters/materialgroups') // Fetching material groups data from another API
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setMaterialGroups(data.data);
                } else {
                    console.error('Error fetching material groups:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching material groups:', error);
            });
}, []);
useEffect(() => {
  const material = materials.find(group => materialId === group.id);
  if (material) {
    setFormData(prevState => ({
      ...prevState,
      name: material.name,
      material_group_id:material.material_group_id,
      created_by: material.created_by
      // Populate other form fields if any
    }));
  }
}, [materialId, materials]);


  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await fetch(`https://genexers.online/genexers/masters/editmaterial/${materialId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      setServerResponse(responseData);
      if (response.ok) {
        window.location.href = '/material';
      } else {
        console.error('Server error:', responseData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }finally{
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [loading,setLoading]=useState(false);
  return (
    <>
  {loading && <SpinnerPopup />}
  <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Material</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
              <select
                name='material_group_id'
                value={formData.material_group_id}
                onChange={handleChange}
                className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required
              >
                <option value="">Group</option>
                {materialgroups.map(materialgroup => (
                  <option key={materialgroup.id} value={materialgroup.id}>
                    {materialgroup.name}
                  </option>
                ))}
              </select>
            
              
                <input
                  type="text"
                  placeholder='Material'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
required="required"
                />
               
            </div>
          
            <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
         
        </form>
        {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
      </div>
    </div>
    </>
  );
}

export default EditMaterial;