import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { AiOutlineClose } from "react-icons/ai";
// import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function EditState({ onClose, stateId }) {
  const [formData, setFormData] = useState({
    name: '',
   
    created_by: ''
});
  console.log("id is"+stateId);
  const modalRef = useRef();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const[setSelectedCountry]=useState('')

  useEffect(() => {
    fetch('https://genexers.online/genexers/masters/states') // Fetching materials data
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setStates(data.data);
            } else {
                console.error('Error fetching states:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching states:', error);
        });

    fetch('https://genexers.online/genexers/masters/countries') // Fetching material groups data from another API
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setCountries(data.data);
            } else {
                console.error('Error fetching material groups:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching material groups:', error);
        });
}, [])
  useEffect(()=>{
    const state=states.find(group=>stateId===group.id);
    if(state){
      setFormData(prevState=>({
        ...prevState,
        name:state.name,
        country_id:state.country_id,
        created_by:state.created_by
      }))
     } },[stateId,states])
     const handleCountryChange = (event) => {
      const selectedCountry = event.target.value;
      setSelectedCountry(selectedCountry);
      setFormData({ ...formData, country_id: selectedCountry });
     
      
    
    };
  const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};
const [serverResponse, setServerResponse] = useState(null);
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    setLoading(true)
    // Make a POST request to the server with form data
    const response = await fetch(`https://genexers.online/genexers/masters/editstate/${stateId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(formData),
  });

  // Parse the response JSON
  const responseData = await response.json();

//     // Update state with the server response
  setServerResponse(responseData);
  if (response.ok) {
    window.location.href = '/state';
  } else {
    // Handle server errors or other unsuccessful responses here
    console.error('Server error:', responseData);
  }
} catch (error) {
  console.error('Error submitting form:', error);
}finally{
  setLoading(false)
}
}

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };
  const [loading,setLoading]=useState(false);
  return (
   <> 
   {loading && <SpinnerPopup />}
     <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit State</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
            <select name='country_id' value={formData.country_id}className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleCountryChange}>
            <option value="">Country</option>
        {countries.map(country => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
          ))}
            </select>
         
            
              <input type="text" placeholder='State' value={formData.name} name='name'  className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleChange}/>
            
         </div>
          
         <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
        </form>
        {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
      </div>
    </div></>
  );
}

export default EditState;
