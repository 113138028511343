import React, {useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'

import './products.css'
// import { AiOutlineClose } from "react-icons/ai";
// import {HiMenuAlt3} from 'react-icons/hi'
// import { MdOutlineDashboard } from "react-icons/md";
// import { FaRegUser } from "react-icons/fa";
// import { GrLogout } from "react-icons/gr";
// import { FiSettings } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from "react-icons/io";


function EditProducts({onClose}){
 
  const [states,setStates]=useState([]);
  const [locations,setLocations]=useState([]);
  const [selectedcountry,setSelectedCountry]=useState('');
  const [selectedstate,setSelectedState]=useState('');
  const [selectedlocation]=useState('');
  const [filteredstate,setFilteredStates]=useState([]);
  const [filteredlocation,setFilteredLocations]=useState([]);
  const [countries,setCountries]=useState([]);
  // const [countries,setCountries]=useState([]);
  
  useEffect(() => {
  
  
    fetch('http://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('http://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setStates(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('http://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLocations(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    notes: '',
    created_by: '1'
  });
  const [image, setImage] = useState('');

  // Create state for the file
  

  

  // Update formData when other inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("inside handlechange");
    console.log(formData);
  };
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setFormData({ ...formData, country_id: selectedCountry });
    console.log("inside country");
    console.log(formData);
    fetchStates(selectedCountry);
    

};

const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setFormData({ ...formData, state_id: selectedState });
    console.log("inside states");
    console.log(formData);
    fetchLocations(selectedState);
   
};
const handleLocationChange = (event) => {
  const selectedlocation = event.target.value;
  setSelectedState(selectedlocation);
  setFormData({ ...formData, location_id: selectedlocation});
  console.log("inside locations");
  console.log(formData);
  
 
};

const fetchStates = (selectedCountry) => {
    const filteredStates = states.filter(state => state.country_id === parseInt(selectedCountry));
    setFilteredStates(filteredStates);
};



const fetchLocations = (selectedState) => {
    const filteredLocations = locations.filter(location => location.state_id === parseInt(selectedState));
    setFilteredLocations(filteredLocations);
};
const handleImage=async(e)=>{
  const file = e.target.files[0];

  if (file) {
    const imageData = new FormData();
    imageData.append('document', file);

    fetch('https://genexers.online/genexers/uploadfile/upload', {
      method: 'POST',
      body: imageData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Handle successful response from the backend
      setImage(data.documentpath)
      console.log('Response from backend:', data);
      // Handle the response data here, if needed
    })
    .catch(error => {
      // Handle error
      console.error('Error sending file to backend:', error);
    });
  }
  setFormData({ ...formData, logo:image});
  console.log("At submit");
  console.log(formData);
}
const handleSubmit = async (e) => {
  e.preventDefault();
    
  try {
    const response = await fetch('https://genexers.online/genexers/vendors/addvendor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData), // Correctly stringify the form data
    });

    const responseData = await response.json();
  
    

    if (response.ok) {
      window.location.href = '/vendors';
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
  }
}

const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}


// const handleImageChange = (e) => {
  // const file = e.target.files[0];

  // if (file) {
  //   const imageData = new FormData();
  //   imageData.append('document', file);

  //   fetch('https://genexers.online/genexers/uploadfile/upload', {
  //     method: 'POST',
  //     body: imageData
  //   })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
  //     // Handle successful response from the backend
  //     setImage(data.documentpath)
  //     console.log('Response from backend:', data);
  //     // Handle the response data here, if needed
  //   })
  //   .catch(error => {
  //     // Handle error
  //     console.error('Error sending file to backend:', error);
  //   });
  // }
  // setFormData({ ...formData, logo:image});
// }
      
          

          

    return(  
      <>     
      <div className="fixed inset-0 flex items-center justify-center z-1 sm:block md:hidden">
      <div ref={modalRef} onClick={closeModal} className="w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg bg-white rounded-lg shadow-lg overflow-hidden flex flex-col gap-5 justify-center">
        <button onClick={onClose} className='self-end m-2'><IoMdCloseCircleOutline size={20}/></button>
        <form method="POST" onSubmit={handleSubmit} className="w-full items-center">
      <div className="w-full flex justify-center">
        <div className="w-[14rem] sm:mx-2 md:w-96">
                    <label className="relative ">
                      <input  type="text" placeholder='Full Name' name='name'   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange} />
                    {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Full Name</span></label> */}
                    </label> </div>
                    </div>
                    
                    <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
    <label className="relative ">
                      <input  type="tel" placeholder='Contact Number' name='phone'    className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
                    {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Contact Number</span></label> */}
                    </label> </div></div>
                    <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
    <label className="relative ">
                      <input  type="text" placeholder='Email Address' name='email'     className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}  />
                    {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Email Address</span></label> */}
                    </label> </div>  </div>
                     <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
    <label className="relative ">
                      <input  type="text" placeholder='Address' name='address'    className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
                    {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Address</span></label> */}
                    </label> </div></div>
                         

                                         

                     <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
    <label className="relative ">
                      <input  type="text" placeholder='Vendor Notes' name='notes'   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
                    {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Note</span></label> */}
                     </label></div></div>
                        
                        <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
                    <select onChange={handleCountryChange} value={selectedcountry} name='country_id' className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required"  >
                            <option value="">Select Country</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.id} >
                                    {country.name}
                                </option>
                            ))}
                        </select></div></div>
                        <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
                    <select  onChange={handleStateChange} value={selectedstate} name='state_id' className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required"  >
                            <option value="">Select State</option>
                            
                            {filteredstate.map(state => (
                                <option key={state.id} value={state.id} >
                                    {state.name}
                                </option>
                            ))}
                        </select></div></div>

                        <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
                    <select   name='location_id' value={selectedlocation}  className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required" onChange={handleLocationChange}   >
                            <option value="">Select Location</option>
                            {filteredlocation.map(location => (
                                <option key={location.id} value={location.id} >
                                    {location.name}
                                </option>
                            ))}

                        </select></div></div>
                        <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96">
    <label className="relative ">
                      <input  type="file" onChange={handleImage} placeholder='' name='logo' accept="image/*"   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required'  />
                      </label>
                     </div>  </div> 
                    
                    <div className="w-full flex justify-center mt-1">
    <div className="w-[14rem] md:w-96"><button type='submit' className="w-full mb-5 px-2 py-1 outline-none transition duration-200m-2 text-lg text-center shadow shadow-black text-white rounded border border-golden bg-new hover:text-new hover:bg-slate-300">Submit</button>
    </div></div>
                   </form></div>




                   
                   
                   
                  </div>

<div className=" inset-0 w-auto mt-5 flex items-center justify-center z-1 sm:hidden md:block">
<div ref={modalRef} onClick={closeModal} className="w-2/3 ml-60 px-5 bg-white shadow-lg rounded-lg flex flex-col gap-5 justify-center">
<button onClick={onClose} className='self-end flex flex-row'><p className=" font-semibold mt-4 mr-2">Edit Product</p><IoMdCloseCircleOutline fontSize={20} className='mt-5'/></button>
 <form method="POST" onSubmit={handleSubmit} className="w-full mb-5 m-2 items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">

  <div >
              <label className="relative ">
                <input  type="text" placeholder='Full Name' name='name'   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange} />
              {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Full Name</span></label> */}
              </label> </div>
              
<div >
<label className="relative ">
                <input  type="tel" placeholder='Contact Number' name='phone'    className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
              {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Contact Number</span></label> */}
              </label> </div>
<div >
<label className="relative ">
                <input  type="text" placeholder='Email Address' name='email'     className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}  />
              {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Email Address</span></label> */}
              </label> </div> 
<div >
<label className="relative ">
                <input  type="text" placeholder='Address' name='address'    className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
              {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Address</span></label> */}
              </label> </div>
<div >
<label className="relative ">
                <input  type="text" placeholder='Vendor Notes' name='notes'   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required' onChange={handleChange}   />
              {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Note</span></label> */}
               </label></div>
<div >
              <select onChange={handleCountryChange} value={selectedcountry} name='country_id' className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required"  >
                      <option value="">Select Country</option>
                      {countries.map(country => (
                          <option key={country.id} value={country.id} >
                              {country.name}
                          </option>
                      ))}
                  </select></div>
<div >
              <select  onChange={handleStateChange} value={selectedstate} name='state_id' className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required"  >
                      <option value="">Select State</option>
                      
                      {filteredstate.map(state => (
                          <option key={state.id} value={state.id} >
                              {state.name}
                          </option>
                      ))}
                  </select></div>
<div >
              <select   name='location_id' value={selectedlocation}  className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required="required" onChange={handleLocationChange}   >
                      <option value="">Select Location</option>
                      {filteredlocation.map(location => (
                          <option key={location.id} value={location.id} >
                              {location.name}
                          </option>
                      ))}

                  </select></div>
<div >
<label className="relative ">
                <input  type="file" onChange={handleImage} placeholder='' name='logo' accept="image/*"   className='w-full px-2 py-1 rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required='required'  />
                </label>
               </div> 
              
              
               <div className="self-end col-span-full md:col-span-2 lg:col-span-4 relative">
                {/* This div spans multiple columns and centers its content */}
                <button className="px-2 py-1 m-2 outline-none transition duration-200m-2 text-lg text-center shadow shadow-black text-white rounded border border-golden bg-new hover:text-new hover:bg-slate-300 absolute bottom-0 right-0">Submit</button>
            </div>
             </form></div>




             
             
             
            </div>

</>


                  

                   
                
           
        
    )
}
export default EditProducts;