import React from 'react';
import './App.css';
import Login from '../src/components/login/login';
// import { PermissionProvider } from './permissionContext';

function App() {
 
  return (

    <div className="App">
       
      <Login />
      
</div>
   
  )  
}

export default App;
