import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'
import { IoMdCloseCircleOutline } from "react-icons/io";
import './settings.css'
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}





function EditMaterialCategory2({onClose, categoryId}){
  const [formData, setFormData] = useState({
    name: '',
    material_category_id: '',
    created_by: '1'
});
  const [serverResponse, setServerResponse] = useState(null);
    // const [materialGroups, setMaterialGroups] = useState([]);
    // const [material, setMaterials] = useState([]);
    const [materialsubcategory, setMaterialSubCategory] = useState([]);
    const [materialcategory, setMaterialCategory] = useState([]);
    // const [selectedMaterialGroup, setSelectedMaterialGroup] = useState('');

    useEffect(() => {
        const fetchMaterialSubCategories = async () => {
            try {
                const response = await axios.post('https://genexers.online/genexers/masters/materialsubcategories', { type_id: null });
                if (response.data.status === 'success') {
                    setMaterialSubCategory(response.data.data);
                    console.log(response.data.data);
                } else {
                    throw new Error(response.data.message || 'Error fetching materials');
                }
            } catch (error) {
                console.error('Error fetching materials:', error);
               
            } 
        };
    
        fetchMaterialSubCategories();
    
        const fetchMaterialCategories = async () => {
            try {
                const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: null });
                if (response.data.status === 'success') {
                    setMaterialCategory(response.data.data);
                    console.log(response.data.data);
                } else {
                    throw new Error(response.data.message || 'Error fetching materials');
                }
            } catch (error) {
                console.error('Error fetching materials:', error);
               
            } 
        };
    
        fetchMaterialCategories();
    }, []);
    useEffect(() => {
        const category = materialsubcategory.find(group => categoryId === group.id);
        if (category) {
          setFormData(prevState => ({
            ...prevState,
            name: category.name,
            material_category_id:category.material_category_id,
            created_by: category.created_by
            // Populate other form fields if any
          }));
        }
      }, [categoryId, materialsubcategory]);

const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
        const response = await fetch(`https://genexers.online/genexers/masters/editmaterialsubcategory/${categoryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(formData),
});

// Parse the response JSON
const responseData = await response.json();

//     // Update state with the server response
setServerResponse(responseData);
if (response.ok) {
  window.location.href = '/materialctgy2';
} else {
  // Handle server errors or other unsuccessful responses here
  console.error('Server error:', responseData);
}
} catch (error) {
console.error('Error submitting form:', error);
}finally{
  setLoading(false)
}
}
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}
const [loading,setLoading]=useState(false);

    return(       
      <>
                  {loading && <SpinnerPopup />}
                  <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Sub Category</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
                         <select required='required' onChange={handleChange} value={formData.material_category_id} name='material_category_id' className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  >
                            <option value="">Material Category</option>
                            {materialcategory.map(materialcategory => (
                                <option key={materialcategory.id} value={materialcategory.id} >
                                    {materialcategory.name}
                                </option>
                            ))}
                        </select>
                      <input  type="text" value={formData.name} placeholder='Sub Category' name='name'  className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required='required' onChange={handleChange} />
                        </div>
                        <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>


                   </form>
                   {serverResponse && <p style={{ color: 'black' }}>{serverResponse.message}</p>}</div>




                   
                   
                   
                  </div></>




                  

                   
                
           
        
    )
}
export default EditMaterialCategory2;