import React, { useState, useEffect, useRef } from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";
import './settings.css'
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function EditMaterialGroup({ onClose, materialGroupId }) {
  const modalRef = useRef();
  
  const [formData, setFormData] = useState({
    name: '',
    created_by: '1'
    // Other form fields if any
  });   

  const [serverResponse, setServerResponse] = useState(null);
  const [materialgroups, setMaterialGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://genexers.online/genexers/masters/materialgroups');
        const data = await response.json();
        if (data.status === 'success') {
          setMaterialGroups(data.data);
        } else {
          console.error('Error fetching material groups:', data.message);
        }
      } catch (error) {
        console.error('Error fetching material groups:', error);
      }
    };
  
    fetchData();
  }, []);
  
  // Use materialGroupId to find the material group within the useEffect
  useEffect(() => {
    const materialGroup = materialgroups.find(group => materialGroupId === group.id);
    if (materialGroup) {
      setFormData(prevState => ({
        ...prevState,
        name: materialGroup.name,
        created_by: materialGroup.created_by
        // Populate other form fields if any
      }));
    }
  }, [materialGroupId, materialgroups]);
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await fetch(`https://genexers.online/genexers/masters/editmaterialgroup/${materialGroupId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      setServerResponse(responseData);
      if (response.ok) {
        window.location.href = '/materialgroup';
      } else {
        console.error('Server error:', responseData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }finally{
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [loading,setLoading]=useState(false);
  return (
    <>
    {loading && <SpinnerPopup />}
 <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Group</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
                <input type="text" placeholder='Group' name='name' value={formData.name} className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleChange} />
                
            </div>
         
            <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>

          
        </form>
        {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
      </div>
    </div>
    </>
  );
}

export default EditMaterialGroup;