import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'
import { IoMdCloseCircleOutline } from "react-icons/io";
import './settings.css'
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}





function AddMaterialCategory2({onClose}){
  const [formData, setFormData] = useState({
    name: '',
    material_category_id: '',
    created_by: '1'
});
  const [serverResponse, setServerResponse] = useState(null);
    const [materialGroups, setMaterialGroups] = useState([]);
    const [material, setMaterials] = useState([]);
    const [selectedmaterial, setSelectedMaterials] = useState([]);
    const [materialcategory, setMaterialCategory] = useState([]);
    const [selectedMaterialGroup, setSelectedMaterialGroup] = useState('');
    const [selectedmaterialcategory,setSelectedMaterialCategory] = useState('');
    const [loading,setLoading]=useState(false);

  useEffect(() => {
    // Fetch material groups data
    fetch('https://genexers.online/genexers/masters/materialgroups')
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setMaterialGroups(data.data);
            } else {
                console.error('Error fetching material groups:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching material groups:', error);
        });
}, []);

const handleMaterialGroupChange = (event) => {
    const selectedGroup = event.target.value;
    setSelectedMaterialGroup(selectedGroup);
    fetchMaterials(selectedGroup);
};
const handleMaterialChange = (event) => {
  const selectedMaterial = event.target.value;
  setSelectedMaterials(selectedMaterial);
  fetchMaterialCategories(selectedMaterial);
};
const handleMaterialCategoryChange = (event) => {
    const selectedMaterialCategory = event.target.value;
    setSelectedMaterialCategory(selectedMaterialCategory);
    setFormData({ ...formData, material_category_id: selectedMaterialCategory })
  };

const fetchMaterials = async (selectedGroup) => {
    try {
        const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: selectedGroup });
        if (response.data.status === 'success') {
            setMaterials(response.data.data);
        } else {
            throw new Error(response.data.message || 'Error fetching materials');
        }
    } catch (error) {
        console.error('Error fetching materials:', error);
    }
};
const fetchMaterialCategories = async (selectedMaterial) => {
  try {
      const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: selectedMaterial });
      if (response.data.status === 'success') {
          setMaterialCategory(response.data.data);
      } else {
          throw new Error(response.data.message || 'Error fetching materials');
      }
  } catch (error) {
      console.error('Error fetching materials:', error);
  }
};

const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setLoading(true)
        const response = await fetch('https://genexers.online/genexers/masters/addmaterialsubcategory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(formData),
});

// Parse the response JSON
const responseData = await response.json();

//     // Update state with the server response
setServerResponse(responseData);
if (response.ok) {
  window.alert("Submitted Successfully");
        setTimeout(() => {
            window.location.href = '/materialctgy2';
          }, 500);
} else {
  // Handle server errors or other unsuccessful responses here
  console.error('Server error:', responseData);
  window.alert('Submission Failed...Please try again');
}
} catch (error) {
console.error('Error submitting form:', error);
window.alert('An error occurred. Please try again later.');
}finally{
    setLoading(false)
}
}
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}
  

    return(   <>    
      {loading && <SpinnerPopup />}
      <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-full bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add Sub Category</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
          <select value={selectedMaterialGroup} onChange={handleMaterialGroupChange} name='material_group_id' className="md:w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedMaterialGroup ? 'black' : 'gray' }}  required="required"  >
                            <option value="">Group</option>
                            {materialGroups.map(materialgroup => (
                                <option key={materialgroup.id} value={materialgroup.id} >
                                    {materialgroup.name}
                                </option>
                            ))}
                        </select>
                        <select name='material_id' value={selectedmaterial} className="md:w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmaterial ? 'black' : 'gray' }}  required="required" onChange={handleMaterialChange}>
                            <option value="">Material</option>
                            {material.map(material => (
                                <option key={material.id} value={material.id}>
                                    {material.name}
                                </option>
                            ))}
                        </select>
                  
                         <select required='required' onChange={handleMaterialCategoryChange} value={selectedmaterialcategory} name='material_category_id' className="md:w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmaterialcategory ? 'black' : 'gray' }}   >
                            <option value="">Category</option>
                            {materialcategory.map(materialcategory => (
                                <option key={materialcategory.id} value={materialcategory.id} >
                                    {materialcategory.name}
                                </option>
                            ))}
                        </select>
                      <input  type="text" placeholder='Sub Category' name='name'  className="md:w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required='required' onChange={handleChange} />
                      {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Material Subcategory</span></label> */}
                     </div>
                   
                   <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
                   </form>
                   {serverResponse && <p style={{ color: 'black' }}>{serverResponse.message}</p>}</div>




                   
                   
                   
                  </div>
                  </>




                  

                   
                
           
        
    )
}
export default AddMaterialCategory2;