import React, { useEffect,useState,Fragment } from 'react';
import { Menu,Transition } from '@headlessui/react'
import { IoHome } from "react-icons/io5";
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { FaUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
// import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './vendors.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { Link } from 'react-router-dom';
import EditVendors from '../vendorss/editvendors'
import AddVendors from '../vendorss/addvendors'
import DataTable from 'react-data-table-component';
// import { CiEdit } from "react-icons/ci";
import { RiEditBoxFill } from "react-icons/ri";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

import { IoMdPersonAdd } from "react-icons/io";
// import { IoMdAdd } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}



function Vendors(){
    const navigate = useNavigate()
    const [vendors, setVendors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loading,setLoading]=useState(false);

    useEffect(() => {
      setLoading(true)
      fetch('https://genexers.online/genexers/vendors/vendors') // Assuming this is the endpoint where your countries data is fetched
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            setVendors(data.data); // Assuming data.data contains an array of countries
          } else {
            console.error('Error fetching countries:', data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
        fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            setCountries(data.data); // Assuming data.data contains an array of countries
          } else {
            console.error('Error fetching countries:', data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
        fetch('https://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            setStates(data.data); // Assuming data.data contains an array of countries
          } else {
            console.error('Error fetching countries:', data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
        fetch('https://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            setLocations(data.data); // Assuming data.data contains an array of countries
          } else {
            console.error('Error fetching countries:', data.message);
          }
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
          setLoading(false)
        });
    }, []);
    const columns=[
        {
            name:'Name',
            selector:row=>row.name,
            sortable:true
        },
        {
            name:'Email ',
            selector:row=>row.email,
            sortable:true
        },
        {
            name:'Contact',
            selector:row=>row.phone,
            sortable:true
        },
        {
            name:'Address',
            selector:row=>row.address,
            sortable:true
        },
        // {
        //     name:'Logo',
        //     selector:row=>row.logo,
        //     sortable:true
        // },
        {
            name:'Notes',
            selector:row=>row.notes,
            sortable:true
        },
        {
            name:'Country',
            selector:row=>row.country,
            sortable:true
        },
        {
            name:'State',
            selector:row=>row.state,
            sortable:true
        },
        {
            name:'Location',
            selector:row=>row.location,
            sortable:true
        },
        {
            name:'Action',
            selector:row=>row.action
        }
      
    ];
    const data = vendors.map(vendor => {
        const location = locations.find(location => vendor.location_id === location.id);
        const locationName = location ? location.name : 'Unknown Location';

        const state = states.find(state =>state.id === location.state_id );
        const stateName = state ? state.name : 'Unknown State';

        const country = countries.find(country =>country.id  ===state.country_id );
        const countryName = country ? country.name : 'Unknown Country';
    
        return {

            id: vendor.id,
            name: vendor.name,
            email: vendor.email,
            phone: vendor.phone,
            address: vendor.address,
            // logo:<img src={`https://genexers.online/genexers/uploads/static/${vendor.logo}`} alt='Vendor logo'/>,
            notes: vendor.notes,
            country: countryName,
            state: stateName,
            location: locationName,
            action:<button onClick={()=>setShowModal(vendor.id)} className=" mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"><RiEditBoxFill className='bg-transparent ml-3' size={20}/></button>
     };
    });
    
    
    const customStyle={
        headCells:{
            style:{
                backgroundColor:"#ededed",
                color:'#896835',
                fontWeight:'bold',
                overflowX: 'auto'

            }
        }
    }

    const [showModal,setShowModal]=useState(false);
    const [showAdd,setShowAdd]=useState(false);
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors',backgroundColor:'black',textColor:'#896835' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout' }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
    return(
    <>       {loading && <SpinnerPopup/>}
     <div className="bg-black h-16 -p-1 flex items-center border-b border-black justify-between flex-wrap">
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
   
  
  <div className="flex items-center gap-1 ml-auto md:ml-650 justify-end"> 
  <IoMdPersonAdd
    onClick={() => setShowAdd(true)}
    className='text-white'fontSize={24}
  />
  
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        
          <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
<div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/6':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>



        {showModal && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <EditVendors onClose={() => setShowModal(false)} vendorId={showModal} />
                    </div>
                )}
     {showAdd && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddVendors onClose={() => setShowAdd(false)} />
                    </div>
                )}
                <div className="text-xl text-gray-900 w-auto overflow-x-auto m-2 ">
                
                
                <DataTable columns={columns}
                        data={data}
                        selectableRows
                        fixedHeader
                        pagination
                        customStyles={customStyle}>
                        
                    </DataTable>
                    </div>

                   
                    
                </div>
            
        </>
    )
}
export default Vendors;