import React, { useEffect,useState,Fragment } from 'react';
import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './products.css'
import {HiMenuAlt3} from 'react-icons/hi'
import DataTable from 'react-data-table-component';
// import { CiEdit } from "react-icons/ci";
// import { RiEditBoxFill } from "react-icons/ri";
import { IoHome } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { FaUser } from "react-icons/fa";
// import { FiSettings } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { MdAddShoppingCart } from "react-icons/md";
import { Link } from 'react-router-dom';
// import DataTable from 'react-data-table-component';
import { Menu,  Transition } from '@headlessui/react'
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { IoSearch } from "react-icons/io5";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
// import { CiEdit } from "react-icons/ci";
import AddProduct from '../../admin/products/addproduct';
import EditProduct from '../../admin/products/editproduct';
// import { RiEditBoxFill } from "react-icons/ri";
// import { IoIosAdd } from "react-icons/io"
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function Products(){
    const navigate = useNavigate()
    // const [products, setProducts] = useState(null);
      
      const [materialGroups, setMaterialGroups] = useState([]);
      const [material, setMaterials] = useState([]);
      const [selectedmaterial, setSelectedMaterials] = useState('');
      const [materialcategory, setMaterialCategory] = useState([]);
      const [materialsubcategory, setMaterialSubCategory] = useState([]);
      const [selectedMaterialGroup, setSelectedMaterialGroup] = useState('');
      const [selectedmaterialcategory, setSelectedMaterialCategory] = useState('');
      const [countries,setCountries] = useState([]);
      const [selectedccountry,setSelectedCountry] = useState('');
      const [selectedstate,setSelectedState] = useState('');
      const [selectedsubcategory,setSelectedSubCategory] = useState('');
      const [selectedlocation,setSelectedLocation] = useState('');

      const [states,setStates] = useState([]);
      const [locations,setLocations] = useState([]);
      const [filteredLocations,setFilteredLocations] = useState([]);
      const [filteredStates,setFilteredStates] = useState([]);
      const [data,setData] = useState([]);
      const [loading,setLoading]=useState(false);

      const [formData, setFormData] = useState({
        material_group_id: '',
        material_id: '',
        material_category_id: '',
        material_sub_category_id: '',
        country_id: '',
        state_id:'',
        location_id: ''
       
      });
    
    //   const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //     });
    // };

      
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch('https://genexers.online/genexers/inventory/products', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            const responseData = await response.json();
            handleProduct(responseData.data);
          } else {
            const responseData = await response.json();
            console.error('Server error:', responseData);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    
      fetchProducts();
    }, [formData]); 
      useEffect(() => {
        setLoading(true);
        // Fetch material groups data
        fetch('https://genexers.online/genexers/masters/materialgroups')
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setMaterialGroups(data.data);
                } else {
                    console.error('Error fetching material groups:', data.message);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching material groups:', error);
                setLoading(false);
            });
    }, []);

  useEffect(() => {
  setLoading(true);
  
    fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setStates(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLocations(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
      setLoading(false);
    });
  }, []);

      
      const handleMaterialGroupChange = (event) => {
        const selectedGroup = event.target.value;
        setSelectedMaterialGroup(selectedGroup);
        setFormData({ ...formData, material_group_id: selectedGroup });
        fetchMaterials(selectedGroup);
    };
    const handleMaterialChange = (event) => {
      const selectedMaterial = event.target.value;
      setSelectedMaterials(selectedMaterial);
      setFormData({ ...formData, material_id: selectedmaterial });
      fetchMaterialCategories(selectedMaterial);
    };
    const handleMaterialCategoryChange = (event) => {
      const selectedMaterialCategory = event.target.value;
      setSelectedMaterialCategory(selectedMaterialCategory);
      setFormData({ ...formData, material_category_id: selectedMaterialCategory });
      fetchMaterialSubCategories(selectedMaterialCategory);
    };
    const handleCountryChange = (event) => {
      const selectedcountry = event.target.value;
      setSelectedCountry(selectedcountry);
      setFormData({ ...formData, country_id: selectedcountry });
      console.log("inside country");
      console.log(formData);
      fetchStates(selectedcountry);
      
  
  };
  const handleLocationChange = (event) => {
    const selectedlocation = event.target.value;
    setSelectedLocation(selectedlocation);
    setFormData({ ...formData, location_id: selectedlocation });
    console.log("inside location");
    console.log(formData);
   
    

};
const handleSubCategoryChange = (event) => {
  const selectedsubcategory = event.target.value;
  setSelectedSubCategory(selectedsubcategory);
  setFormData({ ...formData, material_sub_category_id: selectedsubcategory });
  console.log("inside subcategory");
  console.log(formData);
  
  

};
  
  const handleStateChange = (event) => {
      const selectedstate = event.target.value;
      setSelectedState(selectedstate);
      setFormData({ ...formData, state_id: selectedstate });
      console.log("inside states");
      console.log(formData);
      fetchLocations(selectedstate);
     
  };
    const fetchMaterials = async (selectedGroup) => {
        try {setLoading(true)
            const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: selectedGroup });
            if (response.data.status === 'success') {
                setMaterials(response.data.data);
            } else {
                throw new Error(response.data.message || 'Error fetching materials');
            }
        } catch (error) {
            console.error('Error fetching materials:', error);
            
        }finally{
          setLoading(false);
        }
    };
    const fetchMaterialCategories = async (selectedMaterial) => {
      try {setLoading(true)
          const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: selectedMaterial });
          if (response.data.status === 'success') {
              setMaterialCategory(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally{
        setLoading(false)
      }
    };

    const fetchMaterialSubCategories = async (selectedMaterialCategory) => {
      try {setLoading(true)
          const response = await axios.post('https://genexers.online/genexers/masters/materialsubcategories', { type_id: selectedMaterialCategory });
          if (response.data.status === 'success') {
              setMaterialSubCategory(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally{
        setLoading(false)
      }
    };
   
  
  const fetchStates = (selectedCountry) => {
      const filteredStates = states.filter(state => state.country_id === parseInt(selectedCountry));
      setFilteredStates(filteredStates);
  };
  
  
  
  const fetchLocations = (selectedState) => {
      const filteredLocations = locations.filter(location => location.state_id === parseInt(selectedState));
      setFilteredLocations(filteredLocations);
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
        const response = await fetch('https://genexers.online/genexers/inventory/products', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(formData),
});
// Parse the response JSON
const responseData = await response.json();

//     // Update state with the server response
// setProducts(responseData.data);

if (response.ok) {
  handleProduct(responseData.data);
} else {
  // Handle server errors or other unsuccessful responses here
  console.error('Server error:', responseData);
}
} catch (error) {
console.error('Error submitting form:', error);
}finally{
  setLoading(false)
}
}
const handleProduct=(products) => {
  if (products) {
      const newData = products.map(product => ({
          id: product.id,
          name: product.product_name,
          // image: <img src={`https://genexers.online/genexers/uploads/static/${product.product_image}`} alt='Vendor logo'/>,
          description: product.product_description,
          group: product.material_group_name,
          material: product.material_name,
          category: product.material_category_name,
          subcategory: product.material_sub_category_name,
          country: product.country_name,
          state: product.state_name,
          location: product.location_name,
          // action: <button onClick={() => setShowEdit(product.id)} className=" mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"><RiEditBoxFill className='bg-transparent ml-3' size={20} /></button>
      }));
      setData(newData);
  }
};
    
const columns=[
  {
      name:'Name',
      selector:row=>row.name,
      sortable:true
  },
  // {
  //     name:'Image ',
  //     selector:row=>row.image,
  //     sortable:true
  // },
  {
      name:'Description',
      selector:row=>row.description,
      sortable:true
  },
  {
      name:'Group',
      selector:row=>row.group,
      sortable:true
  },
  {
      name:'Material',
      selector:row=>row.material,
      sortable:true
  },
  {
      name:'Category',
      selector:row=>row.category,
      sortable:true
  },
  {
      name:'Sub Category',
      selector:row=>row.subcategory,
      sortable:true
  },
  {
      name:'Country',
      selector:row=>row.country,
      sortable:true
  },
  {
      name:'State',
      selector:row=>row.state,
      sortable:true
  },
  {
      name:'Location',
      selector:row=>row.location
  },
//   {
//     name:'Action',
//     selector:row=>row.action
// }

];  

const customStyle={
  headCells:{
      style:{
          backgroundColor:"#ededed",
          color:'#896835',
          fontWeight:'bold'
      }
  }
}   

     
  

    
    const [showAdd,setShowAdd]=useState(false);
    const [showEdit,setShowEdit]=useState(false);
    
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products',backgroundColor:'black',textColor:'#896835' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout' }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
    return(
    <>       {loading && <SpinnerPopup />}
    <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
    {/* <div className="relative flex ml-4 md:ml-10"> {/* Adjust margin based on screen size */}
  {/* <HiOutlineSearch fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" />
  <input
    type="text"
    placeholder="Search..."
    className="text-sm focus:outline-none active:outline-none border border-gray-300 w-full md:w-[24rem] h-10 pl-11 pr-4 rounded-sm"
  /> */}
  
 
{/* </div>  */}
  
<div className="flex items-center gap-1 ml-auto md:ml-650 justify-end"> 
  <MdAddShoppingCart
    onClick={() => setShowAdd(true)}
    className='text-white'fontSize={24}
  />
 
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
<div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/6':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>


        {showAdd && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddProduct onClose={() => setShowAdd(false)} />
                    </div>
                )}
                {showEdit && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <EditProduct onClose={() => setShowEdit(false)} productId={showEdit} />
                    </div>
                )}
     
                <div className="text-xl text-gray-900 w-full  ">
                
                
                   
                  
                <div class="w-full h-auto md:h-10 flex flex-wrap bg-white rounded p-4 md:p-8 text-xs md:text-sm">
    <form method="POST" onSubmit={handleSubmit} class="flex flex-wrap flex-row gap-1 m-2 -ml-4 mt-2 ">
       <div className="flex flex-row w-full md:w-auto">
           <select value={selectedMaterialGroup}  onChange={handleMaterialGroupChange} name='material_group_id' className='w-1/2 md:w-1/2 md:px-2 ml-1 py-1 h-auto md:h-10 text-gray-400 text-sm md:text-sm border-2 outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white relative z-2 rounded shadow-2xl border-lightgolden border-opacity-30 backdrop-filter backdrop-blur-sm' style={{ color: selectedMaterialGroup ? 'black' : 'gray'}}>
                <option value="">Group</option>
                {materialGroups.map(materialgroup => (
                    <option  key={materialgroup.id} value={materialgroup.id} >
                        {materialgroup.name}
                    </option>
                ))}
            </select>
            <select value={selectedmaterial}  onChange={handleMaterialChange} name='material_id' className='w-1/2 md:w-1/2 ml-1 mr-4 px-2 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedmaterial ? 'black' : 'gray'}} >
                <option value="">Material</option>
                {material.map(material => (
                    <option key={material.id} value={material.id} >
                        {material.name}
                    </option>
                ))}
            </select>
       </div> 
       <div className="flex flex-row w-full md:w-auto"> 
            <select  value={selectedmaterialcategory}  onChange={handleMaterialCategoryChange}  name='material_category_id' className='w-1/2 md:w-28 ml-1 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedmaterialcategory ? 'black' : 'gray' }} >
                <option value="">Category</option>
                {materialcategory.map(materialcategory => (
                    <option key={materialcategory.id} value={materialcategory.id} >
                        {materialcategory.name}
                    </option>
                ))}
            </select>
            <select  onChange={handleSubCategoryChange} value={selectedsubcategory}  name='material_sub_category_id' className='w-1/2 mr-4 md:w-28 ml-1  py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedsubcategory ? 'black' : 'gray' }}  >
                <option value="">Sub Category</option>
                {materialsubcategory.map(materialsubcategory => (
                    <option key={materialsubcategory.id} value={materialsubcategory.id} >
                        {materialsubcategory.name}
                    </option>
                ))}
            </select>
       </div>
       <div className="flex flex-row w-full md:w-auto">
            <select  value={selectedccountry} onChange={handleCountryChange}  name='country_id' className='w-1/2 md:w-28 ml-1 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm '  style={{ color: selectedccountry ? 'black' : 'gray'}}>
                <option value="">Country</option>
                {countries.map(country => (
                    <option key={country.id} value={country.id} >
                        {country.name}
                    </option>
                ))}
            </select>
            <select  value={selectedstate} onChange={handleStateChange}  name='state_id' className='w-1/2 md:w-28 px-2 mr-4 py-1 h-10 ml-1 text-gray-400 text-sm border-2  outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl border-lightgolden  border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedstate ? 'black' : 'gray' }} >
                <option value="">State</option>
                {filteredStates.map(state => (
                    <option key={state.id} value={state.id} >
                        {state.name}
                    </option>
                ))}
            </select>
       </div>
       <div className="flex sm:flex-row w-full md:w-auto"> 
            <select  onChange={handleLocationChange} value={selectedlocation} name='location_id' className='w-3/4 md:w-28 ml-1 px-1 py-1 h-10 text-gray-400 text-sm border-2  outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl border-lightgolden   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedlocation ? 'black' : 'gray'}} >
                <option value="">Location</option>
                {filteredLocations.map(location => (
                    <option key={location.id} value={location.id} >
                        {location.name}
                    </option>
                ))}
            </select>
            <button type='submit' className="w-1/4 md:w-1/2 mr-4 px-6 ml-2 py-1 h-10 text-white text-center bg-lightgolden text-3xl border-2 outline-none border-black focus:border-lightgolden focus:text-black transition duration-200 hover:text-new hover:bg-slate-300   relative z-2 rounded shadow-2xl  border-r-0 border-b-0 border-opacity-30 backdrop-filter backdrop-blur-sm "> <IoSearch/> </button>
       </div>
   </form>
</div>

<div className="text-sm text-gray-900 w-full m-2 mt-5 overflow-x-auto ">
                
         
                <DataTable columns={columns}
                        data={data}
                        selectableRows
                        fixedHeader
                        pagination
                        customStyles={customStyle}>
                        
                    </DataTable>
                    </div>
                    </div></div> 

                   
              
            
        </>
    )
}
export default Products;