import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';

// import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function EditLocation({ onClose,locationId }) {
  const [formData, setFormData] = useState({
    name: '',
    state_id: '',
    created_by: '1'
  });
  console.log("id is "+ locationId);
  const modalRef = useRef();
  const [states, setStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [serverResponse, setServerResponse] = useState(null);

  useEffect(() => {
        fetch('http://genexers.online/genexers/masters/locations') // Fetching materials data
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setLocations(data.data);
                } else {
                    console.error('Error fetching Locations:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching Locations:', error);
            });
        fetch('https://genexers.online/genexers/masters/states') // Fetching materials data
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setStates(data.data);
                    setFilteredStates(data.data);
                } else {
                    console.error('Error fetching States:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching States:', error);
            });

        
    }, []);
    useEffect(()=>{
      const location=locations.find(group=>locationId===group.id);
      if(location){
        setFormData(prevState=>({
          ...prevState,
          name:location.name,
          state_id:location.state_id,
          created_by:location.created_by
        }))
      }
    },[locationId,locations])
    const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
  };

  const handleStateChange = async (e) => {
    const searchTerm = e.target.value.toLowerCase();
  
  
      // Find the selected state by its name
      const selectedState = states.find(state => state.name.toLowerCase() === searchTerm);
      // If a state is found, set its ID in the formData, otherwise set an empty string
      setFormData({
        ...formData,
        [e.target.name]: selectedState ? selectedState.id : '',
      });
    }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await fetch(`https://genexers.online/genexers/masters/editlocation/${locationId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      setServerResponse(responseData);
      if (response.ok) {
        window.location.href = '/location';
      } else {
        console.error('Server error:', responseData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }finally{
      setLoading(false)
    }
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };
  const [loading,setLoading]=useState(false);
  return (
   <> 
 
 {loading && <SpinnerPopup />}
 <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Location</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
      <input
        type="text"
        list="states"
        name='state_id' 
        className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"onChange={handleStateChange}
        required
        placeholder="Search and select state"
      />
      <datalist className='w-full' value={formData.state_id} id="states">
        {filteredStates.map(state => (
          <option key={state.id} value={state.name}/>
        ))}
      </datalist>
    
  
      
        <input 
          type="text" 
          placeholder='Location' 
          name='name' value={formData.name}
          className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" 
          onChange={handleChange}
        />
        
    
    
  </div>

  <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
  
</form>
    {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
  </div>
</div></>
  );
}

export default EditLocation;