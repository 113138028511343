import React, { useState,useRef } from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";

import './settings.css'
// import { AiOutlineClose } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}




function AddUom({onClose}){
    const [formData, setFormData] = useState({
        name:'',
        created_by:'1'
        // Other form fields if any
      });   
      
      const [serverResponse, setServerResponse] = useState(null);
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true)
          // Make a POST request to the server with form data
          const response = await fetch('https://genexers.online/genexers/masters/adduom', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(formData),
          });
      
          // Parse the response JSON
          const responseData = await response.json();
      
      //     // Update state with the server response
          setServerResponse(responseData);
          if (response.ok) {
            window.alert("Submitted Successfully");
      setTimeout(() => {
          window.location.href = '/uom';
        }, 500);
          } else {
            // Handle server errors or other unsuccessful responses here
            console.error('Server error:', responseData);
            window.alert('Submission Failed...Please try again');
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          window.alert('An error occurred. Please try again later.');
        }finally{
          setLoading(false)
        }
      }
      
        const handleChange = (e) => {
          // const { name, value } = e.target;
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        };
      
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}

const [loading,setLoading]=useState(false);


    return(       
    <>  
    {loading && <SpinnerPopup />}
    <div className="fixed inset-0 items-center justify-center z-1 hidden">
    <div ref={modalRef} onClick={closeModal} className="w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg bg-white rounded-lg shadow-lg overflow-hidden flex flex-col gap-5 justify-center">
      <button onClick={onClose} className='self-end m-2'><IoMdCloseCircleOutline size={20}/></button>
      <form method="POST" onSubmit={handleSubmit} className="w-full items-center">
    
          <div className="w-full flex justify-center">
  <div className="w-[14rem] md:w-96">
                  <label className="relative ">
                    <input  type="text" placeholder='Uom' name='name'  className='w-full px-2 py-1   rounded border border-gray-400 outline-none focus:border-blue-500 focus:text-black transition duration-200' required onChange={handleChange} />
                  {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Material</span>*/}
                  </label> </div>
                  </div>
                  
                 
                  <div className="w-full flex justify-center mt-1">
  <div className="w-[14rem] md:w-96"><button className="w-full mb-5 px-2 py-1 outline-none transition duration-200m-2 text-lg text-center shadow shadow-black text-white rounded border border-golden bg-new hover:text-new hover:bg-slate-300">Submit</button>
 </div></div>
                 </form>
                 {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}</div>

</div>
<div className="   justify-center items-center hidden">
  <div ref={modalRef} onClick={closeModal} className="w-full max-w-sm bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add UoM</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-4">
        <input type="text" placeholder="Uom" name="name" className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange} />
        {/* Additional input fields if needed */}
      </div>
      
      <div className="self-end">
        <button className="bg-lightgolden hover:text-black text-white m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
    </form>
    {serverResponse && <p style={{ color: 'black' }}>{serverResponse.message}</p>}
  </div>
</div>


<div className="  flex items-center justify-center z-1 ">
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add UoM</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row  gap-4">
        <input type="text" placeholder="Uom" name="name" className="flex-row px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange} />
        {/* <input type="text" placeholder="Uom" name="name" className="flex-row px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange} />
        <input type="text" placeholder="Uom" name="name" className="flex-row px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange} />
        Additional input fields if needed */}
      </div>
      
      <div className="self-end">
        <button className="bg-lightgolden hover:text-black text-white m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
    </form>
    {serverResponse && <p style={{ color: 'black' }}>{serverResponse.message}</p>}
  </div>
</div>

</>




                  

                   
                
           
        
    )
}
export default AddUom;