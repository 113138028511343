import React,{useState} from 'react';
import './login.css';
import Tilt from 'react-parallax-tilt';
import { FaSpinner } from 'react-icons/fa'; 
import logo from '../../img/GeneXers-4.jpg';

function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function Login() {
  const [formData, setFormData] = useState({
    username:'',
    password:''
    // Other form fields if any
  });
  const [permissions, setPermissions] = useState({});
  // const [serverResponse, setServerResponse] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // Make a POST request to the server with form data
      const response = await fetch('https://genexers.online/genexers/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData).toString(),
      });

      // Parse the response JSON
      const responseData = await response.json();
      // const parsedPermissions = JSON.parse(responseData.data.permissions);
      // setPermissions(parsedPermissions);
      

  //     // Update state with the server response
      console.log(responseData);
      if (responseData.status === 'success') {
        const permissionsString = responseData.data.permissions;
      const permissions = JSON.parse(permissionsString);
        setPermissions(permissions);
        const sessionData = {
          username: responseData.data.username,
          dashboard:true,
          mss:permissions.mss,
          products:permissions.products,
          settings:permissions.settings,
          vendors:permissions.vendors,
          logout:true
          // Other user data...
        };
        sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
      
// Access each permission individually

        window.alert('Login successful.');
        setTimeout(() => {
          window.location.href = '/dashboard';
          console.log(sessionData);
        }, 2000); // Redirect after 2 seconds
      } else {
        window.alert('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      window.alert('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
    
  }
  const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
      // const { name, value } = e.target;
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

  return (
    <div className="bg-[url('/src/img/pixelcut-2export.png')] bg-center bg-cover  md:max-w-full w-screen h-screen absolute overflow-hidden flex flex-col justify-center items-center" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>{/* <div className='h-350 w-350 bg-gradient-to-r from-green-400 to-blue-500 rounded-full absolute left-2/3 -top-56 animate-pulse'></div>
      <div className='h-350 w-350 bg-gradient-to-r from-red-400 via-pink-500 to-purple-500 rounded-full absolute top-96 -left-20 animate-pulse'></div> */}
 {loading && <SpinnerPopup />}
<Tilt>

  <div className='container w-[14rem] h-[14rem]  md:h-96 md:w-96 bg-black   relative z-2 rounded-2xl shadow-5xl border border-r-0 border-b-0 border-opacity-30 backdrop-filter backdrop-blur-lg '>
<form method="POST" onSubmit={handleSubmit} enctype="application/x-www-form-urlencoded" className=' flex flex-col items-center '>
  {/* <div className='font-poppins text-new text-2xl tracking-wider '>Login Form</div> */}
  <img src={logo} alt='' className=' w-1/4 h-2/4 mt-2'/>
  <input type="text" placeholder=" Username" className=" w-2/3 px-2 py-1 bg-active md:mt-16 mt-5 rounded-lg border border-gray-400 outline-none focus:border-lightgolden focus:text-black transition duration-200" name="username" required="required"  onChange={handleChange} />
  <input type="password" placeholder=" Password" className=" w-2/3 px-2 py-1 bg-active mt-2 md:mt-5 rounded-lg border border-gray-400 outline-none focus:border-lightgolden focus:text-black transition duration-200" name="password" required="required"  onChange={handleChange}/>
  
  <button type="submit" className='w-1/2 px-2 py-1 text-white bg-lightgolden mt-5 rounded-3xl  outline-none focus:border-gray-200 focus:text-black transition duration-200'> 
              Login</button>
</form>
{/* {serverResponse && <p style={{color:'black'}}>{serverResponse.status}</p>} */}
</div>

</Tilt>
</div>


   
  )  
}

export default Login;