import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}

function AddLocation({ onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    state_id: '',
    created_by: '1'
  });

  const modalRef = useRef();
  const [states, setStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [serverResponse, setServerResponse] = useState(null);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get('https://genexers.online/genexers/masters/states');
        if (response.data.status === 'success') {
          setStates(response.data.data);
          setFilteredStates(response.data.data);
        } else {
          throw new Error(response.data.message || 'Error fetching materials');
        }
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    };
    fetchStates();
  }, []);

  const handleChange = async (e) => {
    const searchTerm = e.target.value.toLowerCase();
  
    if (e.target.name === 'name') {
      setFormData({
        ...formData,
        name: e.target.value, // Update the 'name' property directly
      });
    } else {
      // Find the selected state by its name
      const selectedState = states.find(state => state.name.toLowerCase() === searchTerm);
      // If a state is found, set its ID in the formData, otherwise set an empty string
      setFormData({
        ...formData,
        [e.target.name]: selectedState ? selectedState.id : '',
      });
    }
}


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await fetch('https://genexers.online/genexers/masters/addlocation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      setServerResponse(responseData);
      if (response.ok) {
        window.alert("Submitted Successfully");
      setTimeout(() => {
          window.location.href = '/location';
        }, 500);
      } else {
        console.error('Server error:', responseData);
        window.alert('Submission Failed...Please try again');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      window.alert('An error occurred. Please try again later.');
    }finally{
      setLoading(false)
    }
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };
  const [loading,setLoading]=useState(false);
  return (
    <>
  {loading && <SpinnerPopup />}
  <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-full bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add Location</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
      <input
        type="text"
        list="states"
        name='state_id'
        className=" px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange}
        required
        placeholder="Search and select state"
      />
      <datalist className='w-full' id="states">
        {filteredStates.map(state => (
          <option key={state.id} value={state.id}>{state.name}</option>
        ))}
      </datalist>
    
      <label className="relative">
        <input 
          type="text" 
          placeholder='Location' 
          name='name' 
          className=" px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" 
          onChange={handleChange}
        />
        
      </label>
    </div>
  

    <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
</form>
    {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
  </div>
</div>
</>
  );
}

export default AddLocation;