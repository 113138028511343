import React from 'react';
import ReactDOM from 'react-dom/client';
import ProtectedRoute from './protectedRoute';
import './index.css';
import App from './App';
import Dashboard from './components/admin/dashboard/dashboard'
import Vendors from './components/admin/vendorss/vendors'
import Products from './components/admin/products/products'
import LogoutButton from './components/logout';
// import AddVendors from './components/admin/vendorss/addvendors'
import Settings from './components/admin/settings/settings'
import Material from './components/admin/settings/material'
import MaterialGroup from './components/admin/settings/materialgrp'
import MaterialCategory1 from './components/admin/settings/materialctgy1'
import MaterialCategory2 from './components/admin/settings/materialctgy2'
import Country from './components/admin/vendorss/country';
import State from './components/admin/vendorss/state';
import Location from './components/admin/vendorss/location';
import Mss from './components/admin/MSS/mss';
import Uom from './components/admin/settings/uom';
import MStandard from './components/admin/settings/mstandard';
import ViewMaterial from './components/admin/settings/viewmaterial';
// import ListVendors from './img/listvendors/listvendors';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes, Route} from 'react-router-dom'
// import VendorSettings from './components/admin/vendorss/vendorsettings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
   
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<App/>}/>
      <Route path='/dashboard' element={<ProtectedRoute Component={Dashboard} requiredPermission="dashboard" />}/>
      <Route path='/vendors' element={<ProtectedRoute Component={Vendors} requiredPermission="vendors" />}/>
      <Route path='/products' element={<ProtectedRoute Component={Products} requiredPermission="products" />}/>

      <Route path='/settings' element={<ProtectedRoute Component={Settings} requiredPermission="settings" />}/>
      <Route path='/country' element={<ProtectedRoute Component={Country} requiredPermission="settings" />}/>
      <Route path='/state' element={<ProtectedRoute Component={State} requiredPermission="settings" />}/>
      <Route path='/location' element={<ProtectedRoute Component={Location} requiredPermission="settings" />}/>
      {/* <Route path='/vendorsettings' element={<VendorSettings/>}/> */}
      <Route path='/material' element={<ProtectedRoute Component={Material} requiredPermission="settings" />}/>
      <Route path='/materialgroup' element={<ProtectedRoute Component={MaterialGroup} requiredPermission="settings" />}/>
      <Route path='/materialctgy1' element={<ProtectedRoute Component={MaterialCategory1} requiredPermission="settings" />}/>
      <Route path='/materialctgy2' element={<ProtectedRoute Component={MaterialCategory2} requiredPermission="settings" />}/>
      {/* <Route path='/mss' element={<Mss/>}/> */}
      <Route path='/mss' element={<ProtectedRoute Component={Mss} requiredPermission="mss" />} />
      <Route path='/uom' element={<ProtectedRoute Component={Uom} requiredPermission="settings" />}/>
      <Route path='/mstandard' element={<ProtectedRoute Component={MStandard} requiredPermission="settings" />}/>
      <Route path='/viewmaterial' element={<ProtectedRoute Component={ViewMaterial} requiredPermission="settings" />}/>
      <Route path='/logout' element={<LogoutButton/>}/>
      <Route path="/viewmaterial/:materialId" element={<ProtectedRoute Component={ViewMaterial} requiredPermission="settings" />}/>
          
          </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
