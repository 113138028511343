import React, { useEffect,useState,Fragment } from 'react';

import { useParams } from 'react-router-dom';
import { Menu,  Transition } from '@headlessui/react';
import { IoHome } from "react-icons/io5";
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { FaUser } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
// import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
// import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './settings.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { IoMdSettings } from "react-icons/io";
import { MdFormatListBulletedAdd } from "react-icons/md";
import AddSelections from './addselections'
import DataTable from 'react-data-table-component';
import EditMaterial from './editmaterial';
import AddSpecification from './addspecification';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { RiEditBoxFill } from "react-icons/ri";



function ViewMaterial(){
    const navigate = useNavigate()
    const { materialId } = useParams();
    
    const [showMaterial,setShowMaterial]=useState(false);
    // const [editMaterial,setEditMaterial]=useState(false);
    const [primaryselections,setPrimarySelections] = useState([]);
    const [advancedselections,setAdvancedSelections] = useState([]);
    const [generalselections,setGenerals] = useState([]);
    // const [showAdd,setShowAdd]=useState(false);
    const [showEdit,setShowEdit]=useState(false);
    const [EditSpec,setShowEditSpec]=useState(false);
    const [selectedSpecificationId]=useState('');
    
    console.log({materialId})
    useEffect(() => {
   

    fetch(`https://genexers.online/genexers/masters/primaryselections?material_id=${materialId}`) // Fetching material groups data from another API
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setPrimarySelections(data.data);
                } else {
                    console.error('Error fetching primaries:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching primaries:', error);
            });
            fetch(`https://genexers.online/genexers/masters/advancedselections?material_id=${materialId}`) // Fetching material groups data from another API
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setAdvancedSelections(data.data);
                } else {
                    console.error('Error fetching primaries:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching primaries:', error);
            });
            fetch(`https://genexers.online/genexers/masters/generalspecifications?material_id=${materialId}`) // Fetching material groups data from another API
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setGenerals(data.data);
                } else {
                    console.error('Error fetching primaries:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching primaries:', error);
            });
           
}, [materialId]);



    

   
    const primarycolumns = [
        {
            name: 'Description',
            selector: row =>row.description,           
            sortable: true
        },
        {
            name: 'Uom',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Measurement Standard',
            selector: row => row.mstandard,
            sortable: true
        },
        {
          name:'Specification Options',
          selector:row=>row.spec,
         
      },
    //    {
    //     name:'Action',
    //     selector:row=>row.action,
       
    // },
        

    ];
    const generalcolumns = [
        {
            name: 'Description',
            selector: row =>row.description,           
            sortable: true
        },
        {
            name: 'Uom',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Measurement Standard',
            selector: row => row.mstandard,
            sortable: true
        },
        {
          name:'Specification Options',
          selector:row=>row.spec,
         
      },
    //    {
    //     name:'Action',
    //     selector:row=>row.action,
       
    // },

    ];
    const advancedcolumns = [
        {
            name: 'Description',
            selector: row =>row.description,           
            sortable: true
        },
        {
            name: 'Uom',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Measurement Standard',
            selector: row => row.mstandard,
            sortable: true
        },
        {
          name:'Specification Options',
          selector:row=>row.spec,
         
      },
    //    {
    //     name:'Action',
    //     selector:row=>row.action,
       
    // },

    ];
   
    const primarydata = primaryselections.map(primary => ({
      id: primary.id,
      description: primary.description,
      uom: primary.uom_name,
      mstandard: primary.measurement_standard_name,
      spec: (
          <div style={{ display: 'flex', alignItems: 'center' }}> {/* Container for select and button */}
              <select value={selectedSpecificationId} className='w-10'>
                  <option></option>
                  {primary.specification_options.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
              </select>
  
              <button
                  className="mb-2 bg-transparent w-12 h-8 hover:bg-white hover:rounded"
                  onClick={() => setShowEditSpec({ id: primary.id, type: 'P',materialid:materialId })}
                  style={{ marginLeft: '8px' }} // Adjust margin for spacing
              >
                  <FaPlus className='ml-2 mt-1' />
              </button>
          </div>
      ),
      // action: (
      //     <button
      //         onClick={() => setShowEdit(primary.id)}
      //         className="mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"
      //         style={{ verticalAlign: 'middle' }} // Adjust vertical alignment
      //     >
      //         <RiEditBoxFill className='bg-transparent ml-3' size={20} />
      //     </button>
      // )
  }));
    const advanceddata = advancedselections.map(advanced => ({
        id: advanced.id,
        description: advanced.description,
        uom:advanced.uom_name,
        mstandard:advanced.measurement_standard_name,
        spec: <div><select value={selectedSpecificationId} className='w-10'>
        <option value=""></option>
        {advanced.specification_options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      
      <button className=" mb-2 bg-transparent w-12 h-8 hover:bg-white hover:rounded" onClick={()=>setShowEditSpec({id:advanced.id,type:'A',materialid:materialId})} >
      <FaPlus className='ml-2 mt-1'/>
      </button></div>
    ,
        // action: <div><button onClick={() => setShowEdit(advanced.id)} className=" mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"><RiEditBoxFill className='bg-transparent ml-3' size={20} /></button>
        // </div>
        }));
    
    const generaldata = generalselections.map(general => ({
      
        id: general.id,
        description: general.description,
        uom:general.uom_name,
        mstandard:general.measurement_standard_name,
        spec: <div>
          <select value={selectedSpecificationId} className='w-10' >
        <option value=""></option>
        {general.specification_options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      
      <button className=" mb-2 bg-transparent w-12 h-8 hover:bg-white hover:rounded" onClick={()=>setShowEditSpec({id:general.id,type:'G',materialid:materialId})} >
      <FaPlus className='ml-2 mt-1'/>
      </button></div>
 
        // action: <button onClick={() => setShowEdit(general.id)} className=" mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"><RiEditBoxFill className='bg-transparent ml-3' size={20} /></button>
    }
    )
    );
    const customStyle={
        headCells:{
            style:{
                backgroundColor:"#ededed",
                color:'#896835',
                fontWeight:'bold'
            }
        },
        cells: {
          style: {
              textAlign: 'left' // Align table data to the left
          }
      }
    }
    const [showTable,setShowTable] = useState(false);   
    const [showAdvanced,setShowAdvanced] = useState(false);  
    const [showGeneral,setShowGeneral] = useState(false); 
    const [activeTab, setActiveTab] = useState(null); // State to track active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
   
const toggleTable = () => {
  setShowTable(!showTable);
}; 
const toggleAdvanced = () => {
  setShowAdvanced(!showAdvanced);
};
const toggleGeneral = () => {
  setShowGeneral(!showGeneral);
};
const [open,setOpen]=useState(true);
const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
const menus = [
  { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
  { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
  { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
  { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
  { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings',backgroundColor:'black',textColor:'#896835' },
  { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout' }
];
const filteredMenus = menus.filter(menu => {
  // Check if sessionData exists and if the user has the required permission
  return sessionData && sessionData[menu.permission];
});
    return(
    <>       
     <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
    {/* <div className="relative flex ml-4 md:ml-10"> {/* Adjust margin based on screen size */}
  {/* <HiOutlineSearch fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" />
  <input
    type="text"
    placeholder="Search..."
    className="text-sm focus:outline-none active:outline-none border border-gray-300 w-full md:w-[24rem] h-10 pl-11 pr-4 rounded-sm"
  /> */}
  
{/* </div> */}
  
<div className="flex items-center gap-1 ml-auto md:ml-650 justify-end"> 
  <MdFormatListBulletedAdd
    onClick={() => setShowMaterial(materialId)}
    className='text-white'fontSize={24}
  />
  
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
<div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/5':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>

        <div className="flex flex-row w-full"><div className="flex flex-col w-full">
  
  <div className="hidden md:block text-[6px] md:text-sm pt-3 bg-white text-lightgolden  w-full h-10 justify-between"><>
  <Link className={`px-3 md:px-24 py-3 font-semibold h-10 hover:bg-active tab ${activeTab === 'primary' ? 'active' : ''} ${activeTab === 'primary' ? 'text-black' : ''}  `} onClick={() => handleTabClick('primary')}>
    PRIMARY SELECTION
  </Link>
  {/* <div className="h-5 border-l border-white  mt-2"></div>  */}
  <Link className={`px-3 md:px-24 py-3 font-semibold h-10 hover:bg-active tab ${activeTab === 'advanced' ? 'active' : ''} ${activeTab === 'advanced' ? 'text-black' : ''}`} onClick={() => handleTabClick('advanced')}>
    ADVANCED SELECTION
  </Link>
  
  {/* <div className="h-5 border-l border-white mt-2"></div>  */}
 
      <Link className={`px-3 md:px-24 py-3 font-semibold h-10 hover:bg-active tab  ${activeTab === 'listvendor' ? 'active' : ''} ${activeTab === 'listvendor' ? 'text-black' : ''} `} onClick={() => handleTabClick('listvendor')}>
        GENERAL SPECIFICATION
      </Link>
      
    
  
 </> </div>
 <div className="sm:block md:hidden pt-3 flex-col text-xs  bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`w-full pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'primary' ? 'active' : ''} ${activeTab === 'primary' ? 'text-black' : ''} `} onClick={() => handleTabClick('primary')}>
        PRIMARY SELECTIONS
      </Link></div>
  <div className="sm:block md:hidden pt-3 flex-col text-xs   bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`w-full pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'advanced' ? 'active' : ''} ${activeTab === 'advanced' ? 'text-black' : ''} `} onClick={() => handleTabClick('advanced')}>
        ADVANCED SELECTIONS
      </Link></div>
      <div className="sm:block md:hidden pt-3 flex-col text-xs bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`w-full pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'listvendor' ? 'active' : ''} ${activeTab === 'listvendor' ? 'text-black' : ''} `} onClick={() => handleTabClick('listvendor')}>
        GENERAL SELECTIONS
      </Link></div>

{activeTab === 'primary' && (
                       <div  className="w-full text-left mt-2 bg-white shadow-lg overflow-x-auto flex flex-col ">
                       <DataTable
               columns={primarycolumns}
               data={primarydata}
               selectableRows
               fixedHeader
               pagination
               customStyles={customStyle}
           />
                   
                   </div>)}

                        {activeTab === 'advanced' && (
                        <div  className="w-full bg-white mt-2 shadow-lg overflow-x-auto flex flex-col gap-5 justify-center">
                                    
                        <DataTable
                columns={advancedcolumns}
                data={advanceddata}
                selectableRows
                fixedHeader
                pagination
                customStyles={customStyle}
            />   
                       
                    </div>)} 
                    
           {activeTab === 'listvendor' && (
            
    <div  className="w-full bg-white mt-2 shadow-lg overflow-x-auto flex flex-col gap-5 justify-center">
    <DataTable
columns={generalcolumns}
data={generaldata}
selectableRows
fixedHeader
pagination
customStyles={customStyle}
/>
     
       
   
</div>)}
          


              </div>  </div>



        {showMaterial && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddSelections onClose={() => setShowMaterial(false)} materialId={materialId} />
                    </div>
                )}
        {EditSpec && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddSpecification onClose={() => setShowEditSpec(false)} MainCategory={EditSpec} />
                    </div>
                )}
                {showEdit && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <EditMaterial onClose={() => setShowEdit(false)} materialId={showEdit} />
                    </div>
                )}
     
                
                        </div>
                                        
                                    
                        
                   
                    

                   
                    
                
            
        </>
    )
}
export default ViewMaterial;