import React, { useEffect } from 'react';


const LogoutButton = () => {


  useEffect(() => {
    // Clear session data
    sessionStorage.removeItem('sessionData'); // Assuming session data is stored in sessionStorage

    // Redirect to login page
    window.location.href = '/';
  },[])

  return (
    <></>
  );
};

export default LogoutButton;