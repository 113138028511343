import React, { useEffect, useState,Fragment } from 'react';
import { Menu,  Transition } from '@headlessui/react'
import {FaArrowCircleRight } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { FaUser } from "react-icons/fa";
import classNames from 'classnames'
// import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './settings.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import AddMaterialGroup from './addmaterialgrp'
import DataTable from 'react-data-table-component';
// import { CiEdit } from "react-icons/ci";
import { RiEditBoxFill } from "react-icons/ri";
import EditMaterialGroup from './editmaterialgrp';
// import { IoMdAdd } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaSpinner } from 'react-icons/fa'; 

function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}



function MaterialGroup(){
    const navigate = useNavigate()
    const [materialgroup, setMaterialGroup] = useState([]);
    const [showMaterialGroup,setShowMaterialGroup]=useState(false);
    const [editMaterialGroup,setEditMaterialGroup]=useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(true);
      fetch('https://genexers.online/genexers/masters/materialgroups') // Assuming this is the endpoint where your countries data is fetched
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            setMaterialGroup(data.data); // Assuming data.data contains an array of countries
          } else {
            console.error('Error fetching countries:', data.message);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
          setLoading(false);
        })
        
    }, []);
    const columns=[
        {
            name:'Material Group',
            selector:row=>row.name,
            sortable:true
        },
        {
            name:'Action',
            selector:row=>row.action
        }
      
    ];
    
    const data = materialgroup.map(materialgroup => ({
        id: materialgroup.id,
        name: materialgroup.name,
        action:<button onClick={()=>setEditMaterialGroup(materialgroup.id)} className=" mb-2 bg-transparent w-12 h-8 hover:bg-slate-400 hover:rounded"><RiEditBoxFill className='bg-transparent ml-3' size={20}/></button>

    }));

    
    const customStyle={
        headCells:{
            style:{
                backgroundColor:"#ededed",
                color:'#896835',
                fontWeight:'bold'
            }
        }
    }
   

    
    
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings',backgroundColor:'black',textColor:'#896835' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout' }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
    return(
    <>       
    {loading && <SpinnerPopup />}
     <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
   
  
<div className="flex items-center gap-1 ml-auto md:ml-650 justify-end"> 
  <MdFormatListBulletedAdd
    onClick={() => setShowMaterialGroup(true)}
    className='text-white'fontSize={24}
  />
  
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
<div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/6':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>
        <div className="flex w-full flex-col">           
        <div className="hidden md:block  text-[6px] md:text-sm  bg-[#ededed] text-lightgolden  pt-2 w-full h-8">
  {/* <div className="w-full md:w-full flex flex-wrap text-lg"> */}
    <Link to="/materialgroup" className='p-1 md:px-8 md:uppercase md:pr-4 md:pl-6 pt-2 bg-white'>
      Group
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div>  */}
    <Link to="/material" className='p-1 md:uppercase md:px-8 pt-2 md:pr-4 hover:bg-white ' >
      Material
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/materialctgy1" className='p-1 md:uppercase md:px-8 pt-2 md:pr-4 hover:bg-white'>
     Category
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/materialctgy2" className='p-1 md:uppercase md:px-8 pt-2 md:pr-4 hover:bg-white'>
    Sub Category
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/uom" className='p-1 md:uppercase md:px-8 md:pr-4 pt-2 hover:bg-white'>
    UoM
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/mstandard" className='p-1 md:uppercase md:px-2 md:pr-4 pt-2 hover:bg-white'>
    Measurement Standard
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/country" className='p-1 md:uppercase md:px-8 md:pr-4 pt-2 hover:bg-white'>
    Country
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/state" className='p-1 md:uppercase md:px-8 md:pr-4 pt-2 hover:bg-white' >
    State
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/location" className='p-1 md:uppercase md:px-8 md:pl-14 pt-2 hover:bg-white'>
    Location
    </Link>
  {/* </div> */}
</div>

<div className=" md:hidden flex text-xs font-semibold bg-[#ededed] text-lightgolden  w-full h-10">
  {/* <div className="w-full md:w-full flex flex-wrap text-lg"> */}
  <Link to="" className='w-1/4 p-1 px-2 md:uppercase  pt-4 hover:bg-white'>
      {/* <FaArrowCircleLeft fontSize={15}/> */}
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div>  */}
    <Link to="/group" className='w-1/2 p-1 uppercase whitespace-nowrap pt-3 mr-4 font-bold  hover:bg-white ' >
      material group
    </Link>
    {/* <div class="h-8 border-l border-white mt-3"></div> */}
    <Link to="/material" className='w-1/4 p-1 md:uppercase pl-8 pt-4 hover:bg-white'>
    <FaArrowCircleRight fontSize={15}/>
    </Link>
   
  {/* </div> */}
</div>

        {showMaterialGroup && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddMaterialGroup onClose={() => setShowMaterialGroup(false)} />
                    </div>
                )}
        {editMaterialGroup && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                         <EditMaterialGroup onClose={() => setEditMaterialGroup(false)} materialGroupId={editMaterialGroup} />
                    </div>
                )}
     
                <div className="text-xl text-gray-900 w-auto m-2 mt-4 ">
                
                
                   
                   <DataTable columns={columns}
                        data={data}
                        selectableRows
                        fixedHeader
                        pagination
                        customStyles={customStyle}>
                        
                    </DataTable>
                    
</div></div>
                   
                    
                </div>
           
        </>
    )
}
export default MaterialGroup;