import React from 'react';

const ProtectedRoute = ({ Component, requiredPermission }) => {
  const WrappedComponent = (props) => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    console.log(sessionData);
    console.log(sessionData[requiredPermission]);
    // Check if sessionData exists and if the user has the required permission
    if (!sessionData || !sessionData[requiredPermission]) {
      return <AccessDeniedPage />;
      
    }

    return <Component {...props} />;
   
  };

  return <WrappedComponent />;
};

const AccessDeniedPage = () => (
  <div>
    <h1>Access Denied</h1>
    <p>You don't have permission to access this page.</p>
 
  </div>
);

export default ProtectedRoute;