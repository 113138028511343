import React, { useState,useEffect,useRef } from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";
// import { IoMdArrowDropdown } from "react-icons/io";
// import { IoMdAdd } from "react-icons/io";

import './settings.css'
// import { AiOutlineClose } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}



function AddSelections({onClose,materialId}){
 
  const [uoms, setUoms] = useState([]);
  const [mstandards, setMStandards] = useState([]);

  const [selectedgroup,setSelectedGroup] = useState('');
  const [selecteduom,setSelectedUom] = useState('');
  const [selectedmstandard,setSelectedMStandard] = useState('');
  const [loading,setLoading]=useState(false);
  
  
  useEffect(() => {
    
    fetch('https://genexers.online/genexers/masters/listuoms') // Fetching material groups data from another API
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setUoms(data.data);
            } else {
                console.error('Error fetching Uoms:', data.message);
            }
        })
        .catch(error => {
            console.error('Error fetching Uoms:', error);
        });
        fetch('https://genexers.online/genexers/masters/listmeasurementstandards') // Fetching material groups data from another API
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setMStandards(data.data);
                } else {
                    console.error('Error fetching Uoms:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching Uoms:', error);
            });
}, []);
  const [formData, setFormData] = useState({
    material_id:materialId,
    description:'',
    uom_id:'',
    measurement_standard_id:'',
    created_by:'1'
    // Other form fields if any
  });   
  const handleGroupChange = (e) => {
    const selectedmaterial = e.target.value;
    setFormData({ ...formData, selection_category: selectedmaterial })
    setSelectedGroup(selectedmaterial)
       };
       const handleUomChange = (e) => {
        const selectedmaterial = e.target.value;
        setFormData({ ...formData, uom_id: selectedmaterial })
        setSelectedUom(selectedmaterial)
           };
           const handleMStandardChange = (e) => {
            const selectedmaterial = e.target.value;
            setFormData({ ...formData, measurement_standard_id: selectedmaterial })
            setSelectedMStandard(selectedmaterial)
               };
  const [serverResponse, setServerResponse] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectionCategory = formData.selection_category;
    if(selectionCategory==='general'){
    try {
      setLoading(true)
      // Make a POST request to the server with form data
      const response = await fetch('https://genexers.online/genexers/masters/addgeneralspecification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
      },
      body:JSON.stringify(formData),
    });

    // Parse the response JSON
    const responseData = await response.json();

//     // Update state with the server response

    setServerResponse(responseData);
    
    if (response.ok) {
      window.alert("Submitted Successfully");
        setTimeout(() => {
            window.location.href = `/viewmaterial/${materialId}`;
          }, 500);
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
      window.alert('Submission Failed...Please try again');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    window.alert('An error occurred. Please try again later.');
  }finally{
    setLoading(false)
  }
}
else{
if(selectionCategory==='primary'){
    try {
      setLoading(true)
      // Make a POST request to the server with form data
      const response = await fetch('https://genexers.online/genexers/masters/addprimaryselection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
      },
      body:JSON.stringify(formData),
    });

    // Parse the response JSON
    const responseData = await response.json();

//     // Update state with the server response

    setServerResponse(responseData);
    
    if (response.ok) {
      window.alert("Submitted Successfully");
        setTimeout(() => {
          window.location.href = `/viewmaterial/${materialId}`;
          }, 500);
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
      window.alert('Submission Failed...Please try again');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    window.alert('An error occurred. Please try again later.');
  }finally{
    setLoading(false)
  }
}else{

if(selectionCategory==='advanced'){
    try {
      setLoading(true)
      // Make a POST request to the server with form data
      const response = await fetch('https://genexers.online/genexers/masters/addadvancedselection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
      },
      body:JSON.stringify(formData),
    });

    // Parse the response JSON
    const responseData = await response.json();

//     // Update state with the server response

    setServerResponse(responseData);
    
    if (response.ok) {
      window.alert("Submitted Successfully");
        setTimeout(() => {
          window.location.href = `/viewmaterial/${materialId}`;
          }, 500);
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
      window.alert('Submission Failed...Please try again');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    window.alert('An error occurred. Please try again later.');
  }finally{
    setLoading(false)
  }
}}}
}
  
const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        
    });
};
        
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}



    return(     <>  
      {loading && <SpinnerPopup />}

<div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add Selections</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 

        <div className="flex flex-col md:flex-row gap-4 text-sm">
        <select name='selection_category' value={selectedgroup} className="md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedgroup ? 'black' : 'gray'}}  required="required"  onChange={handleGroupChange} >
            <option value="">Selections</option>
          <option value='primary'> Primary</option>
          <option value='advanced'> Advanced</option>
          <option value='general'> General</option>
         </select>
         <select name='measurement_standard_id' value={selectedmstandard} className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selectedmstandard ? 'black' : 'gray'}}  required="required"  onChange={handleMStandardChange} >
            <option value="">Measurement Standard</option>
        {mstandards.map(mstandard => (
          <option key={mstandard.id} value={mstandard.id}>
            {mstandard.name}
          </option>
          ))}
            </select>
            </div><div className="flex flex-col md:flex-row gap-4 text-sm">
                   <select name='uom_id' value={selecteduom} className="md:w-1/2  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" style={{ color: selecteduom ? 'black' : 'gray'}}  required="required"  onChange={handleUomChange} >
            <option value="">Uom</option>
        {uoms.map(uom => (
          <option key={uom.id} value={uom.id}>
            {uom.name}
          </option>
          ))}
            </select>
           
            <input  type="text" placeholder='Description'  name='description'  className="  md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required" onChange={handleChange} />
         
                  </div>
                   <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div></form>
                {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}
            </div>
        </div>
</>




                  

                   
                
           
        
    )
}
export default AddSelections;