import React, { useState,Fragment } from 'react';
import { FaUser } from "react-icons/fa";
import { Menu,  Transition } from '@headlessui/react'
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { IoMdSettings } from "react-icons/io";
// import axios from 'axios';
import logo from '../../../img/GeneXers-4.jpg'
import './dashboard.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { IoHome } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
// import { FiSettings } from "react-icons/fi";
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import EditVendors from '../vendorss/editvendors'

import { MdOutlineProductionQuantityLimits } from "react-icons/md";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'


const data = [
	{
		name: 'Jan',
		Expense: 4000,
		Income: 2400
	},
	{
		name: 'Feb',
		Expense: 3000,
		Income: 1398
	},
	{
		name: 'Mar',
		Expense: 2000,
		Income: 9800
	},
	{
		name: 'Apr',
		Expense: 2780,
		Income: 3908
	},
	{
		name: 'May',
		Expense: 1890,
		Income: 4800
	},
	{
		name: 'Jun',
		Expense: 2390,
		Income: 3800
	},
	{
		name: 'July',
		Expense: 3490,
		Income: 4300
	},
	{
		name: 'Aug',
		Expense: 2000,
		Income: 9800
	},
	{
		name: 'Sep',
		Expense: 2780,
		Income: 3908
	},
	{
		name: 'Oct',
		Expense: 1890,
		Income: 4800
	},
	{
		name: 'Nov',
		Expense: 2390,
		Income: 3800
	},
	{
		name: 'Dec',
		Expense: 3490,
		Income: 4300
	}
]


function Dashboard(){
    const navigate = useNavigate()

    const [showModal,setShowModal]=useState(false);
    // const [showAdd,setShowAdd]=useState(false);
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard',backgroundColor:'black',textColor:'#896835' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout'
    }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
    function BoxWrapper({ children }) {
        return <div className="bg-white rounded-sm p-8  border h-auto w-auto border-lightgolden flex items-center">{children}</div>
    }
    return(
    <>       
    
    {/* <div className="flex gap-2 bg-transparent w-screen h-full"> */}
    <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
    {/* <div className="relative flex ml-4 md:ml-10"> Adjust margin based on screen size */}
  {/* <HiOutlineSearch fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" /> */}
  {/* <input */}
    {/* type="text" */}
    {/* placeholder="Search..." */}
    {/* className="text-sm focus:outline-none active:outline-none border border-gray-300 w-full md:w-[24rem] h-10 pl-11 pr-4 rounded-sm" */}
  {/* /> */}
  
{/* </div> */}
  
  <div className="flex items-center gap-2 ml-auto md:ml-650 justify-end"> {/* ml-auto pushes the content to the right */}
  
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
              <div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/5':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor}}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden  min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-200 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2> */}
                    {/* <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>



        {showModal && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <EditVendors onClose={() => setShowModal(false)} />
                    </div>
                )}
     
                <div className="text-xl text-gray-900 ">
                <div className="flex flex-col md:flex-row gap-4 md:gap-0 m-3 mr-10 w-auto">
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
					<IoBagHandle className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Sales</span>
					<div className="flex items-center">
						<strong className="text-xl text-lightgolden font-semibold">$54232</strong>
						{/* <span className="text-sm text-green-500 pl-2">+343</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
					<IoPieChart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Expenses</span>
					<div className="flex items-center">
						<strong className="text-xl text-lightgolden font-semibold">$3423</strong>
						{/* <span className="text-sm text-green-500 pl-2">-343</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoCart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Orders</span>
					<div className="flex items-center">
						<strong className="text-xl text-lightgolden font-semibold">12313</strong>
						{/* <span className="text-sm text-red-500 pl-2">-30</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
					<IoPeople className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Customers</span>
					<div className="flex items-center">
						<strong className="text-xl text-lightgolden font-semibold">16432</strong>
						{/* {/* <span className="text-sm text-red-500 pl-2">-43</span> */}
					</div>
				</div>
			</BoxWrapper> 
            <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
					<IoPeople className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Vendors</span>
					<div className="flex items-center">
						<strong className="text-xl text-lightgolden font-semibold">16432</strong>
						{/* <span className="text-sm text-red-500 pl-2">-43</span> */}
					</div>
				</div>
			</BoxWrapper>
		</div>
        <div className="h-[22rem] w-auto bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
                <strong className="text-gray-700 font-medium">Transactions</strong>
                <div className="mt-3 w-full flex-1 text-xs">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{ top: 20, right: 10, left: -10, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Income" fill="#0ea5e9" />
                            <Bar dataKey="Expense" fill="#ea580c" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
		</div>
</div>
        

                
                
                    </div>

                   
                    
                
            
        </>
    )
}
export default Dashboard;