import React, { useEffect,useState,Fragment } from 'react';
// import ProtectedRoute from '../../../protectedRoute';
// import fileDownload from 'js-file-download';
import axios from 'axios';
import logo from './../../../img/GeneXers-4.jpg';
import { IoHome } from "react-icons/io5";
import './mss.css'
import {HiMenuAlt3} from 'react-icons/hi'
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
// import { IoMdArrowDropdown } from "react-icons/io";
import { GrLogout } from "react-icons/gr";
// import { FiSettings } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa';
// import { MdAddShoppingCart } from "react-icons/md";
import { Link } from 'react-router-dom';
// import DataTable from 'react-data-table-component';
import { Menu,  Transition } from '@headlessui/react'
// import { HiOutlineBell,HiOutlineChatAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { IoSearch } from "react-icons/io5";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
// import { CiEdit } from "react-icons/ci";
import AddProduct from '../../../components/admin/products/addproduct';
import EditProduct from '../../../components/admin/products/editproduct';


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}




function ListVendors(){
  
    const navigate = useNavigate()
    const [vendors, setVendors] = useState(null);
      
      const [materialGroups, setMaterialGroups] = useState([]);
      const [material, setMaterials] = useState([]);
      const [selectedmaterial, setSelectedMaterials] = useState('');
      const [materialcategory, setMaterialCategory] = useState([]);
      const [materialsubcategory, setMaterialSubCategory] = useState([]);
      const [selectedMaterialGroup, setSelectedMaterialGroup] = useState('');
      const [selectedmaterialcategory, setSelectedMaterialCategory] = useState('');
      const [countries,setCountries] = useState([]);
      const [selectedccountry,setSelectedCountry] = useState('');
      const [selectedstate,setSelectedState] = useState('');
      const [states,setStates] = useState([]);
      const [locations,setLocations] = useState([]);
      const [filteredLocations,setFilteredLocations] = useState([]);
      const [filteredStates,setFilteredStates] = useState([]);
      const [primaryselections,setPrimarySelections] = useState([]);
      const [advancedselections,setAdvancedSelections] = useState([]);
      const [selectedlocation,setSelectedLocation] = useState('');
      const [selectedprimary,setSelectedPrimary] = useState('');
      const [selectedadvanced,setSelectedAdvanced] = useState('');
      const [selectedsubcategory,setSelectedSubCategory] = useState('');
      const [selectedvendor,setSelectedVendor] = useState('');
      const [formSubmitted, setFormSubmitted] = useState(false);
      // const [view, setView] = useState("basic");

      const [formData, setFormData] = useState({
        material_group_id: '',
        material_id: '',
        material_category_id: '',
        material_sub_category_id: '',
        country_id: '',
        state_id:'',
        location_id: '',
        vendor_id:'',
        order_quantity:'',
        landing_price:''
       
      });
    
    //   const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //     });
    // };

    const [activeTab, setActiveTab] = useState(null); // State to track active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
      

      useEffect(() => {
        setLoading(true);
        // Fetch material groups data
        fetch('https://genexers.online/genexers/masters/materialgroups')
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setMaterialGroups(data.data);
                } else {
                    console.error('Error fetching material groups:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching material groups:', error);
            })
            setLoading(false);
    }, []);
  useEffect(() => {
    setLoading(true);
  
    fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setStates(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLocations(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    }); setLoading(false);
  }, []);

      
      const handleMaterialGroupChange = (event) => {
        const selectedGroup = event.target.value;
        setSelectedMaterialGroup(selectedGroup);
        setFormData({ ...formData, material_group_id: selectedGroup });
        fetchMaterials(selectedGroup);
    };
    const handleMaterialChange = (event) => {
      const selectedMaterial = event.target.value;
      setSelectedMaterials(selectedMaterial);
      setFormData({ ...formData, material_id: selectedmaterial });
      fetchPrimarySelection(selectedMaterial);
      fetchAdvancedSelection(selectedMaterial);
      fetchMaterialCategories(selectedMaterial);
    };
    const handleMaterialCategoryChange = (event) => {
      const selectedMaterialCategory = event.target.value;
      setSelectedMaterialCategory(selectedMaterialCategory);
      setFormData({ ...formData, material_category_id: selectedMaterialCategory });
      fetchMaterialSubCategories(selectedMaterialCategory);
    };
    const handleCountryChange = (event) => {
      const selectedcountry = event.target.value;
      setSelectedCountry(selectedcountry);
      setFormData({ ...formData, country_id: selectedcountry });
      console.log("inside country");
      console.log(formData);
      fetchStates(selectedcountry);
      
  
  };
  
  const handleStateChange = (event) => {
      const selectedstate = event.target.value;
      setSelectedState(selectedstate);
      setFormData({ ...formData, state_id: selectedstate });
      console.log("inside states");
      console.log(formData);
      fetchLocations(selectedstate);
     
  };
  const handleLocationChange = (event) => {
    const selectedlocation = event.target.value;
    setSelectedLocation(selectedlocation);
    setFormData({ ...formData, location_id: selectedlocation });
    console.log("inside location");
    console.log(formData);
   
    

};

const handleSubCategoryChange = (event) => {
  const selectedsubcategory = event.target.value;
  setSelectedSubCategory(selectedsubcategory);
  setFormData({ ...formData, material_sub_category_id: selectedsubcategory });
  console.log("inside subcategory");
  console.log(formData);
  
  

};
const handleAdvancedChange = (event) => {
  const selectedadvanced = event.target.value;
  setSelectedAdvanced(selectedadvanced);
  setFormData({ ...formData, advancespecification_id: selectedadvanced });
  console.log("inside advanced");
  console.log(formData);
 
  

};
const handlePrimaryChange = (event) => {
const selectedprimary = event.target.value;
setSelectedPrimary(selectedprimary);
setFormData({ ...formData, primaryspecification_id: selectedprimary });
console.log("inside primary");
console.log(formData);



};
const handleCheckbox = (event) => {
  const selectedvendor = event.target.value;
  setSelectedVendor(selectedvendor);
  setFormData({ ...formData, vendor_id: selectedvendor });

  console.log("inside primary");
  console.log(formData);
  
  
  
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("inside handlechange");
    console.log(formData);
  };
  
  const handleQuote=async (e)=>{
    e.preventDefault();
    console.log("Reached")
    
    fetch('https://genexers.online/genexers/quotation/generatepdf') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
      window.open(`https://genexers.online/genexers/uploads/static/${data.fileName}`)
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
  }

    const fetchMaterials = async (selectedGroup) => {
        try { setLoading(true);
            const response = await axios.post('https://genexers.online/genexers/masters/materials', { type_id: selectedGroup });
            if (response.data.status === 'success') {
                setMaterials(response.data.data);
            } else {
                throw new Error(response.data.message || 'Error fetching materials');
            }
        } catch (error) {
            console.error('Error fetching materials:', error);
        }finally {
          setLoading(false); // Reset loading state after form submission
        }
    };
    const fetchMaterialCategories = async (selectedMaterial) => {
      try { setLoading(true);
          const response = await axios.post('https://genexers.online/genexers/masters/materialcategories', { type_id: selectedMaterial });
          if (response.data.status === 'success') {
              setMaterialCategory(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally {
        setLoading(false); // Reset loading state after form submission
      }
    };
    const fetchPrimarySelection = async (selectedMaterial) => {
      try { setLoading(true);
          const response = await axios.get(`https://genexers.online/genexers/masters/primaryselections?material_id=${selectedMaterial}`);
          if (response.data.status === 'success') {
              setPrimarySelections(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally {
        setLoading(false); // Reset loading state after form submission
      }
    };
    const fetchAdvancedSelection = async (selectedMaterial) => {
      try {setLoading(true);
          const response = await axios.get(`https://genexers.online/genexers/masters/advancedselections?material_id=${selectedMaterial}`);
          if (response.data.status === 'success') {
              setAdvancedSelections(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally {
        setLoading(false); // Reset loading state after form submission
      }
    };

    const fetchMaterialSubCategories = async (selectedMaterialCategory) => {
      try { setLoading(true);
          const response = await axios.post('https://genexers.online/genexers/masters/materialsubcategories', { type_id: selectedMaterialCategory });
          if (response.data.status === 'success') {
              setMaterialSubCategory(response.data.data);
          } else {
              throw new Error(response.data.message || 'Error fetching materials');
          }
      } catch (error) {
          console.error('Error fetching materials:', error);
      }finally {
        setLoading(false); // Reset loading state after form submission
      }
    };
   
  
  const fetchStates = (selectedCountry) => {
      const filteredStates = states.filter(state => state.country_id === parseInt(selectedCountry));
      setFilteredStates(filteredStates);
  };
  
  
  
  const fetchLocations = (selectedState) => {
      const filteredLocations = locations.filter(location => location.state_id === parseInt(selectedState));
      setFilteredLocations(filteredLocations);
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {setLoading(true);
        const response = await fetch('https://genexers.online/genexers/vendors/searchvendors', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(formData),
});
// Parse the response JSON
const responseData = await response.json();

//     // Update state with the server response
setVendors(responseData.data);
setFormSubmitted(true);
if (response.ok) {
  // window.location.href = '/materialctgy2';
} else {
  // Handle server errors or other unsuccessful responses here
  console.error('Server error:', responseData);
}
} catch (error) {
console.error('Error submitting form:', error);
}finally{
  setLoading(false);
}
}
    


// Function to toggle the dropdown
// const toggleTable = () => {
//   setShowTable(!showTable);
//   // setShowAdvanced(!showAdvanced);
//   // setView("basic")
// }; 
const toggleAdvanced = () => {
  setShowAdvanced(!showAdvanced);
  // setShowTable(!showTable);
  // setView("advanced")
}; 


const [showTable,setShowTable] = useState(false);   
const [showAdvanced,setShowAdvanced] = useState(false);   

     
  

    
    const [showAdd,setShowAdd]=useState(false);
    const [showEdit,setShowEdit]=useState(false);
    const[loading,setLoading]=useState(false);
    
    const [open,setOpen]=useState(true);
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const menus = [
      { name: "Dashboard", link: '/dashboard', icon: IoHome,permission: 'dashboard' },
      { name: "MSS", link: '/mss', icon: MdOutlineDashboard, permission: 'mss',backgroundColor:'black',textColor:'#896835' },
      { name: "Vendors", link: '/vendors', icon: FaRegUser, permission: 'vendors' },
      { name: "Products", link: '/products', icon: MdOutlineProductionQuantityLimits, permission: 'products' },
      { name: "Settings", link: '/settings', icon: IoMdSettings, permission: 'settings' },
      { name: "Logout", link: '/logout', icon: GrLogout,permission: 'logout'
    }
    ];
    const filteredMenus = menus.filter(menu => {
      // Check if sessionData exists and if the user has the required permission
      return sessionData && sessionData[menu.permission];
    });
   
    
    return(
      
          <>    
          {loading && <SpinnerPopup />}
    <div className="bg-black h-16 px-4 flex items-center border-b border-black justify-between flex-wrap">
    
  <div className="flex items-center w-full md:w-auto"> {/* Adjust width based on screen size */}
    <img src={logo} alt='Logo' className='h-16'/> {/* Adjust the height as needed */}
    <div className="flex items-center gap-2 ml-auto md:ml-650 justify-end"> {/* ml-auto pushes the content to the right */}
  
  <Menu as="div" className="relative">
    <div>
      <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
        <span className="sr-only">Open user menu</span>
        <FaUser className="h-6 w-6 rounded-full bg-black text-white  bg-cover bg-no-repeat bg-center"/>
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/profile')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Your Profile
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              onClick={() => navigate('/settings')}
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Settings
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active && 'bg-gray-100',
                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
              )}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div></div></div>
<div className='w-screen h-full flex'>  <div className={`bg-lightgolden z-10 min-h-screen ${open ? 'w-1/6':'w-10'}  hidden md:block lg:block duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm text-white  font-medium p-2 hover:bg-gray-200 hover:text-lightgolden rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor }}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2>
                </div>
            </Link>
        ))}
        </div></div>

        <div className={`bg-lightgolden min-h-screen ${open ? 'w-8':'w-8'}  md:hidden lg:hidden duration-500 text-gray-100 px-0`}>
                    <div className=" p-0 flex justify-end">
                        <HiMenuAlt3 size={26} className="cursor-pointer text-white" onClick={()=>setOpen(!open)}/>
                    </div>
                    <div className='mt-4 flex flex-col gap-4 relative'>
        {filteredMenus?.map((menu, i) => (
            <Link to={menu?.link} key={i} className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`} style={{ backgroundColor: menu?.backgroundColor,color:menu?.textColor}}>
                <div>
                    {React.createElement(menu?.icon, { size: "20" })}
                </div>
                <div className="flex flex-col">
                    {/* <h2
                        style={{ transitionDelay: `${i + 3}00ms` }}
                        className={`whitespace-pre  duration-500  z${!open && 'opacity-0 text-transparent translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
                    <h2 className={`${open && 'hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 ml-5
                     overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>{menu?.name}</h2> */}
                </div>
            </Link>
        ))}
        </div></div>


        {showAdd && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <AddProduct onClose={() => setShowAdd(false)} />
                    </div>
                )}
                {showEdit && (
                    <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                        <EditProduct onClose={() => setShowEdit(false)} productId={showEdit} />
                    </div>
                )}
     
                <div className="text-xl text-gray-900 w-full   ">
                
                
                   
                  
                <div class="w-auto h-auto md:h-10 flex flex-wrap bg-white rounded p-4 md:p-4 text-xs md:text-sm">
                <form method="POST" onSubmit={handleSubmit} class="flex flex-wrap flex-row gap-1 m-2 ml-4  ">
       <div className="flex flex-row w-full md:w-auto">
           <select value={selectedMaterialGroup}  onChange={handleMaterialGroupChange} required='required' name='material_group_id' className='w-1/2 md:w-1/2 md:px-2 py-1 h-auto md:h-10 text-gray-400 text-sm md:text-sm border-2 outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white relative z-2 rounded shadow-2xl border-lightgolden border-opacity-30 backdrop-filter backdrop-blur-sm' style={{ color: selectedMaterialGroup ? 'black' : 'gray'}}>
                <option value="">Group</option>
                {materialGroups.map(materialgroup => (
                    <option  key={materialgroup.id} value={materialgroup.id} >
                        {materialgroup.name}
                    </option>
                ))}
            </select>
            <select value={selectedmaterial}  onChange={handleMaterialChange} required='required' name='material_id' className='w-1/2 md:w-1/2 ml-1 px-2 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedmaterial ? 'black' : 'gray'}} >
                <option value="">Material</option>
                {material.map(material => (
                    <option key={material.id} value={material.id} >
                        {material.name}
                    </option>
                ))}
            </select>
       </div> 
       <div className="flex flex-row w-full md:w-auto"> 
            <select  value={selectedmaterialcategory}  onChange={handleMaterialCategoryChange}  name='material_category_id' className='w-1/2 md:w-28 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedmaterialcategory ? 'black' : 'gray' }} >
                <option value="">Category</option>
                {materialcategory.map(materialcategory => (
                    <option key={materialcategory.id} value={materialcategory.id} >
                        {materialcategory.name}
                    </option>
                ))}
            </select>
            <select  onChange={handleSubCategoryChange} value={selectedsubcategory}  name='material_sub_category_id' className='w-1/2 md:w-28 ml-1  py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedsubcategory ? 'black' : 'gray' }}  >
                <option value="">Sub Category</option>
                {materialsubcategory.map(materialsubcategory => (
                    <option key={materialsubcategory.id} value={materialsubcategory.id} >
                        {materialsubcategory.name}
                    </option>
                ))}
            </select>
       </div>
       <div className="flex flex-row w-full md:w-auto">
            <select  value={selectedccountry} onChange={handleCountryChange}  name='country_id' className='w-1/2 md:w-28 py-1 h-10 text-gray-400 text-sm border-2 border-lightgolden outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl   border-opacity-30 backdrop-filter backdrop-blur-sm '  style={{ color: selectedccountry ? 'black' : 'gray'}}>
                <option value="">Country</option>
                {countries.map(country => (
                    <option key={country.id} value={country.id} >
                        {country.name}
                    </option>
                ))}
            </select>
            <select  value={selectedstate} onChange={handleStateChange}  name='state_id' className='w-1/2 md:w-28 px-2 py-1 h-10 ml-1 text-gray-400 text-sm border-2  outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl border-lightgolden  border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedstate ? 'black' : 'gray' }} >
                <option value="">State</option>
                {filteredStates.map(state => (
                    <option key={state.id} value={state.id} >
                        {state.name}
                    </option>
                ))}
            </select>
       </div>
       <div className="flex sm:flex-row w-full md:w-auto"> 
            <select  onChange={handleLocationChange} value={selectedlocation} name='location_id' className='w-3/4 md:w-28 px-1 py-1 h-10 text-gray-400 text-sm border-2  outline-none focus:border-lightgolden focus:text-black transition duration-200 bg-white  relative z-2 rounded shadow-2xl border-lightgolden   border-opacity-30 backdrop-filter backdrop-blur-sm ' style={{ color: selectedlocation ? 'black' : 'gray'}} >
                <option value="">Location</option>
                {filteredLocations.map(location => (
                    <option key={location.id} value={location.id} >
                        {location.name}
                    </option>
                ))}
            </select>
            <button type='submit' className="w-1/4 md:w-1/2 ml-1 px-6 py-1 h-10 text-white text-center bg-lightgolden text-3xl border-2 outline-none border-black focus:border-lightgolden focus:text-black transition duration-200 hover:text-new hover:bg-slate-300   relative z-2 rounded shadow-2xl  border-r-0 border-b-0 border-opacity-30 backdrop-filter backdrop-blur-sm "> <IoSearch/> </button>
       </div>
   </form>
</div>
  

                      
                      
<div className="flex flex-col md:flex-row"><div className="flex max-w-auto flex-col w-full mt-10">
  
{selectedmaterial && (<div className="hidden md:block pt-3 flex-col md:flex-row text-[6px] md:text-xs  bg-white text-lightgolden  w-full h-10 justify-between"><>
  <Link className={`w-1/2 py-3 md:px-16 pt-3 font-semibold hover:bg-active tab ${activeTab === 'primary' ? 'active' : ''} ${activeTab === 'primary' ? 'text-black' : ''} pt-2 `} onClick={() => handleTabClick('primary')}>
    PRIMARY SELECTION
  </Link>
  {/* <div className="h-5 border-l border-white  mt-2"></div>  */}
  <Link className={`w-1/2 pt-3 py-3 font-semibold px-3 md:px-16 hover:bg-active tab ${activeTab === 'advanced' ? 'active' : ''} ${activeTab === 'advanced' ? 'text-black' : ''}`} onClick={() => handleTabClick('advanced')}>
    ADVANCED SELECTION
  </Link>
  
  {/* <div className="h-5 border-l border-white mt-2"></div>  */}
  {formSubmitted && vendors && (
    <>
      <Link className={`w-1/2 pt-3 py-3 font-semibold p-2 px-3 md:px-16 hover:bg-active tab ${activeTab === 'listvendor' ? 'active' : ''} ${activeTab === 'listvendor' ? 'text-black' : ''} `} onClick={() => handleTabClick('listvendor')}>
        AVAILABLE VENDORS
      </Link>
      {/* <div className="h-5 border-l border-white mt-2"></div> */}
      <Link className={`w-1/2 pt-3 py-3 font-semibold p-2 px-6 md:px-16 md:pl-24 hover:bg-active tab ${activeTab === 'getqoute' ? 'active' : ''} ${activeTab === 'getquote' ? 'text-black' : ''} `} onClick={() => handleTabClick('getquote')}>
        QUOTE 
      </Link>
    </>
  )}
 </> </div>
 )}

{selectedmaterial && (<>
  <div className="sm:block md:hidden pt-3 flex-col text-xs  bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between ">
      <Link className={`sm:block md:hidden flex-col text-xs  bg-white    w-full h-10 justify-between pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'primary' ? 'active text-black w-full' : ''} `} onClick={() => handleTabClick('primary')}>
        PRIMARY SELECTIONS
      </Link>
      </div>
  <div className="sm:block md:hidden pt-3 flex-col text-xs   bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`sm:block md:hidden flex-col text-xs   bg-white    w-full h-10 justify-between pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'advanced' ? 'active' : ''} ${activeTab === 'advanced' ? 'text-black' : ''} `} onClick={() => handleTabClick('advanced')}>
        ADVANCED SELECTIONS
      </Link></div>
  {/* <div className="h-5 border-l border-white mt-2"></div>  */}
  {formSubmitted && vendors && (
    <><div className="sm:block md:hidden pt-3 flex-col text-xs   bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`sm:block md:hidden flex-col text-xs  bg-white   w-full h-10 justify-between pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'listvendor' ? 'active' : ''} ${activeTab === 'listvendor' ? 'text-black' : ''} `} onClick={() => handleTabClick('listvendor')}>
        AVAILABLE VENDORS
      </Link></div>
      <div className="sm:block md:hidden pt-3 flex-col text-xs  bg-white text-lightgolden hover:bg-active  w-full h-10 justify-between">
      <Link className={`sm:block md:hidden flex-col text-xs  bg-white    w-full h-10 justify-between pt-3 py-3 font-semibold p-2  hover:bg-active tab ${activeTab === 'getquote' ? 'active' : ''} ${activeTab === 'getquote' ? 'text-black' : ''} `} onClick={() => handleTabClick('getquote')}>
         QUOTE 
      </Link></div>
    </>
  )}</>
 
 )}
{activeTab === 'primary' && (
                       <div className='border border-gray-200 pt-2 sm:mt-5 max-w-full md:w-auto'>
                       <button className='flex  items-center justify-between w-full  px-6 py-3 text-[10px] md:text-xs text-gray-500 bg-active uppercase tracking-wider' onClick={toggleAdvanced}>
                <span className='text-center font-medium'>Primary Selections</span>
                
               </button>
                       <table className="w-full divide-y  divide-gray-200 overflow-x-auto">
                                           <thead className="bg-gray-50 text-xs md:text-sm text-left">
                              <tr>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">Description</th>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">UoM</th>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase whitespace-nowrap tracking-wider">Measurement Standard</th>
                                <th scope="col" className="px-1 pl-8 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">Specifications</th>
                              </tr>
                            </thead>
                            <tbody className="bg-gray-50 divide-y divide-gray-200 text-xs md:text-sm">
                              {primaryselections.map((row) => (
                                <tr key={row.id}>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.description}</td>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.uom_name}</td>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.measurement_standard_name}</td>
                                  <td className="px-1 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left "><select value={selectedprimary}  onChange={handlePrimaryChange}  name='primaryspecification_id' className='px-1 md:px-6 font-normal whitespace-nowrap text-sm text-left  bg-white rounded border  border-lightgolden ' style={{ color: selectedprimary ? '#896835' : 'slate-200' }} >
                            <option value="">Specifications</option>
                            {row.specification_options.map(option => (
                                <option key={option.id} value={option.id} >
                                    {option.name}
                                </option>
                            ))}
                        </select></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>)}

                        {activeTab === 'advanced' && (
                       <div className='border border-gray-200 pt-2 sm:mt-5 max-w-full md:w-auto'>
                       <button className='flex  items-center justify-between w-full  px-6 py-3 text-[10px] md:text-xs text-gray-500 bg-active uppercase tracking-wider' onClick={toggleAdvanced}>
                <span className='text-center font-medium'>Advanced Selections</span>
                
               </button>
                       <table className="w-full divide-y  divide-gray-200 overflow-x-auto">
                                           <thead className="bg-gray-50 text-xs md:text-sm text-left">
                              <tr>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">Description</th>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">UoM</th>
                                <th scope="col" className="px-1 md:px-6 py-2 font-normal   text-gray-500 md:uppercase whitespace-nowrap tracking-wider">Measurement Standard</th>
                                <th scope="col" className="px-1 pl-8 md:px-6 py-2 font-normal   text-gray-500 md:uppercase tracking-wider">Specifications</th>
                              </tr>
                            </thead>
                            <tbody className="bg-gray-50 divide-y divide-gray-200 text-xs md:text-sm">
                              {advancedselections.map((row) => (
                                <tr key={row.id}>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.description}</td>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.uom_name}</td>
                                  <td className="px-3 md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left ">{row.measurement_standard_name}</td>
                                  <td className="px-1  md:px-6 py-2 whitespace-nowrap  text-lightgolden font-left "><select value={selectedadvanced}  onChange={handleAdvancedChange}  name='advancedspecification_id' className='px-1 md:px-6 font-normal whitespace-nowrap text-sm text-left  bg-white rounded border  border-lightgolden ' style={{ color: selectedadvanced ? '#896835' : 'gray' }} >
                            <option value="">Specifications</option>
                            {row.specification_options.map(option => (
                                <option key={option.id} value={option.id} >
                                    {option.name}
                                </option>
                            ))}
                        </select></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>)}
                    
           {activeTab === 'listvendor' && (
    <div className='border border-gray-200 pt-2 sm:mt-5'>
        <button className='flex  items-center justify-between w-full  px-6 py-3  text-[10px] md:text-xs   text-gray-500 bg-active uppercase tracking-wider' onClick={toggleAdvanced}>
 <span className='text-center font-medium'>Available Vendors</span>
 
</button>
        <table className="w-full divide-y divide-gray-200 overflow-x-auto">
                            <thead className="bg-gray-50 text-xs md:text-sm">
                              <tr>
                                <th scope="col" className="px-6 py-2 text-left   font-normal text-gray-500 md:uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-2 text-left font-normal text-gray-500 md:uppercase tracking-wider">Contact</th>
                                <th scope="col" className="px-6 py-2 text-left  font-normal text-gray-500 md:uppercase tracking-wider">Select</th>
                                
                              </tr>
                            </thead>
                            <tbody className="bg-white  divide-y divide-gray-200 text-xs md:text-sm max-w-sm md:max-w-auto">
                              {vendors.map((vendor) => (
                                <tr key={vendor.id}>
                                  <td className="px-6 py-2 whitespace-nowrap  text-left text-lightgolden">{vendor.name}</td>
                                  <td className="px-6 py-2 whitespace-nowrap  text-left text-lightgolden">{vendor.phone}</td>
                                  <td className="px-6 py-2 whitespace-nowrap  text-left text-lightgolden"> <input type="checkbox" name='vendor_id' value={selectedvendor} onClick={handleCheckbox}/></td>
                                  
                                </tr>
                              ))}
                            </tbody>
                          </table>
    </div>)}
          

{activeTab === 'getquote' && (
     <div className='border border-gray-200 pt-2 sm:mt-5 max-w-full md:w-auto'><form method='POST' onSubmit={handleQuote}>
     <button className='flex justify-between w-full  px-6 py-3 text-xs md:text-xs  text-gray-500 bg-active uppercase tracking-wider' onClick={toggleAdvanced}>
<span className='font-medium'>Request for Quote</span>

</button>
     <table className="w-full divide-y  divide-gray-200 ">
                         <thead className="bg-gray-50 text-xs md:text-xs">
            <tr>
                                <th scope="col" className="md:px-6 py-2 px-4 text-left font-normal text-gray-500 uppercase tracking-wider">Order Quantity(Multiples of MOQ)</th>
                                <th scope="col" className="md:px-6 py-2 px-4 text-left  font-normal text-gray-500 uppercase tracking-wider">Total Landing Price to the Location</th>
                                
                                
                              </tr>
                            </thead>
                            <tbody className="bg-white  divide-y divide-gray-200 text-xs md:text-sm">
                              
                                <tr >
                                  <td className="md:px-6 py-2 px-2 whitespace-nowrap  text-left "><input type="text" name='order_quantity' placeholder='Enter here' className='px-4 md:px-6 py-2 text-lightgolden whitespace-nowrap  text-left font-normal bg-white rounded border border-lightgolden ' onChange={handleChange}/></td>
                                  <td className="md:px-6 py-2 px-2 whitespace-nowrap  text-left "><input type="text" name='landing_price' placeholder='Enter here' className='px-4 md:px-6 py-2 whitespace-nowrap text-lightgolden text-left font-normal bg-white rounded border border-lightgolden ' onChange={handleChange}/></td>
                                 
                                </tr>
                               
                            </tbody>
                          </table> 
                          <div className="justify-center mt-10 col-span-full md:col-span-2 lg:col-span-4 relative">
                          <div className="flex justify-end mr-5">
        <button className="bg-lightgolden text-white  text-sm m-1 p-2 rounded focus:outline-none  hover:bg-gray-200 hover:text-black">Get Quote</button>
        <button type='submit'  className="bg-active text-black  text-sm m-1 p-2 rounded focus:outline-none focus:bg-gray-200 hover:bg-gray-200">Cancel</button>
      </div></div></form>
    </div>
)}
              </div>  
                        
                        


 
                   </div></div> </div>

</>
              
    
    )
}
// export default ProtectedRoute(ListVendors, 'mss');
export default ListVendors;




































































































// import React, { useState } from 'react';

// function ListVendors() {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission here, such as sending data to the server
//     console.log(formData);
//   };

//   return (
//     <div className="w-auto mx-auto bg-white rounded p-8 shadow-md">
//       <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
//       <form onSubmit={handleSubmit} className="flex flex-col md:flex-row justify-center items-center gap-4">
//         <input type="text" id="firstName" name="firstName"  onChange={handleChange} placeholder="First Name" className="w-full md:w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
//         <input type="text" id="lastName" name="lastName"  onChange={handleChange} placeholder="Last Name" className="w-full md:w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
//         <input type="email" id="email" name="email"  onChange={handleChange} placeholder="Email Address" className="w-full md:w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
//         <textarea id="message" name="message"  onChange={handleChange} placeholder="Message" className="w-full md:w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
//         <button type="submit" className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:bg-blue-700 hover:bg-blue-600">Submit</button>
//       </form>
//     </div>
//   );
// }

// export default ListVendors;