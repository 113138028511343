import React, {useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'

import './vendors.css'
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}


function EditVendors({onClose,vendorId}){
 
  const [states,setStates]=useState([]);
  const [locations,setLocations]=useState([]);
  // const [selectedcountry,setSelectedCountry]=useState('');
  // const [selectedstate,setSelectedState]=useState('');
  // const [selectedlocation,setSelectedLocation]=useState('');
  const [filteredstate,setFilteredStates]=useState([]);
  const [filteredlocation,setFilteredLocations]=useState([]);
  const [countries,setCountries]=useState([]);
  const [vendors,setVendors]=useState([]);
  
  useEffect(() => {
    fetch('https://genexers.online/genexers/vendors/vendors') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setVendors(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
  
    fetch('https://genexers.online/genexers/masters/countries') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setCountries(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
        
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/states') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setStates(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
    fetch('https://genexers.online/genexers/masters/locations') // Assuming this is the endpoint where your countries data is fetched
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLocations(data.data); // Assuming data.data contains an array of countries
      } else {
        console.error('Error fetching countries:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching countries:', error);
    });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    notes: '',
    location_id: '',
    created_by: '1'
  });
  const [image, setImage] = useState('');

  // Create state for the file
  useEffect(() => {
    const vendor = vendors.find(vendor => vendorId === vendor.id);
    if (vendor) {
      setFormData(prevState => ({
        ...prevState,
        name: vendor.name,
        phone:vendor.phone,
        email: vendor.email,
        address: vendor.address,
        notes: vendor.notes,
        country_id: vendor.country_id,
        state_id: vendor.state_id,
        location_id: vendor.location_id,
        created_by: vendor.created_by,
        // Populate other form fields if any
      }));
    }
  }, [vendorId,vendors]);

  

  // Update formData when other inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("inside handlechange");
    console.log(formData);
  };
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    // setSelectedCountry(selectedCountry);
    setFormData({ ...formData, country_id: selectedCountry });
    console.log("inside country");
    console.log(formData);
    fetchStates(selectedCountry);
    

};

const handleStateChange = (event) => {
    const selectedState = event.target.value;
    // setSelectedState(selectedState);
    setFormData({ ...formData, state_id: selectedState });
    console.log("inside states");
    console.log(formData);
    fetchLocations(selectedState);
   
};

const fetchStates = (selectedCountry) => {
    const filteredStates = states.filter(state => state.country_id === parseInt(selectedCountry));
    setFilteredStates(filteredStates);
};



const fetchLocations = (selectedState) => {
    const filteredLocations = locations.filter(location => location.state_id === parseInt(selectedState));
    setFilteredLocations(filteredLocations);
};
const handleImage=async(e)=>{
  const file = e.target.files[0];

  if (file) {
    const imageData = new FormData();
    imageData.append('document', file);

    fetch('https://genexers.online/genexers/uploadfile/upload', {
      method: 'POST',
      body: imageData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Handle successful response from the backend
      setImage(data.documentpath)
      console.log('Response from backend:', data);
      // Handle the response data here, if needed
    })
    .catch(error => {
      // Handle error
      console.error('Error sending file to backend:', error);
    });
  }
  setFormData({ ...formData, logo:image});
  console.log("At submit");
  console.log(formData);
}
const handleSubmit = async (e) => {
  e.preventDefault();
    
  try {
    setLoading(true)
    const response = await fetch(`https://genexers.online/genexers/vendors/editvendor/${vendorId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData), // Correctly stringify the form data
    });

    const responseData = await response.json();
  
    

    if (response.ok) {
      window.location.href = '/vendors';
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
  }finally{
    setLoading(false)
  }
}

const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}


          

const [loading,setLoading]=useState(false);       

    return(       
      <>
      {loading && <SpinnerPopup />}
                 <div className=''>
  <div ref={modalRef} onClick={closeModal} className=" max-w-xs md:max-w-full bg-white rounded p-8 shadow-md overflow-y-auto">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Edit Vendor</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4">
    <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="text" placeholder='Full Name' name='name' value={formData.name}  className="md:w-1/3 w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  onChange={handleChange} />
                      <input  type="tel" placeholder='Contact Number' name='phone'  value={formData.phone}  className="md:w-1/3 w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange}   />
                      <input  type="text" placeholder='Email Address' name='email' value={formData.email}    className="md:w-1/3 w-auto px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  onChange={handleChange}  />
                    </div>
                      <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="text" placeholder='Address' name='address' value={formData.address}    className=" md:md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  onChange={handleChange}   />
                      <input  type="text" placeholder='Vendor Notes' name='notes' value={formData.notes} className=" md:md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange}   />
                    </div>
                        
                    <div className="flex flex-row gap-4 text-sm">
                    <select onChange={handleCountryChange}  name='country_id' className=" w-1/3 md:px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  >
                            <option value="">Country</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.id} >
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    <select  onChange={handleStateChange}  name='state_id' className=" w-1/3 md:px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"  >
                            <option value="">State</option>
                            
                            {filteredstate.map(state => (
                                <option key={state.id} value={state.id} >
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    <select   name='location_id'   className="w-1/3 md:px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" onChange={handleChange}   >
                            <option value="" className='text-gray-200 ' >Location</option>
                            {filteredlocation.map(location => (
                                <option key={location.id} value={location.id} >
                                    {location.name}
                                </option>
                            ))}

                        </select></div>
                        <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="file" onChange={handleImage} placeholder='' name='logo' accept="image/*"   className=" w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"   />
                      
                     </div> 
                     <div className="self-end">
        <button className="bg-lightgolden hover:text-black text-white m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Update</button>
        <button className="bg-active text-black m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
                   </form></div>




                   
                   
                   
                  </div>
</>



                  

                   
                
           
        
    )
}
export default EditVendors;