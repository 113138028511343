import React, { useState,useRef } from 'react';
// import axios from 'axios';
// import logo from './../../img/GeneXers-4.jpg'

import './settings.css'
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa'; 


function SpinnerPopup() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <FaSpinner className="animate-spin mr-2 bg-transparent w-10 h-10 text-gray-500" fontSize={16} />
    </div>
  );
}




function AddMaterialGroup({onClose}){
const modalRef= useRef();
const closeModal=(e)=>{
  if(modalRef.current===e.target){
    onClose();
  }
}
const [formData, setFormData] = useState({
  name:'',
  created_by:'1'
  // Other form fields if any
});   

const [serverResponse, setServerResponse] = useState(null);
const [loading,setLoading]=useState(false);
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    setLoading(true)
    // Make a POST request to the server with form data
    const response = await fetch('https://genexers.online/genexers/masters/addmaterialgroups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(formData),
    });

    // Parse the response JSON
    const responseData = await response.json();

//     // Update state with the server response
    setServerResponse(responseData);
    if (response.ok) {
      window.alert("Submitted Successfully");
        setTimeout(() => {
            window.location.href = '/materialgroup';
          }, 500);
    } else {
      // Handle server errors or other unsuccessful responses here
      console.error('Server error:', responseData);
      window.alert('Submission Failed...Please try again');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    window.alert('An error occurred. Please try again later.');
  }finally{
    setLoading(false)
  }
}

  const handleChange = (e) => {
    // const { name, value } = e.target;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


    return(       
      <>
                  {loading && <SpinnerPopup />}
                  <div className=''>
  <div ref={modalRef} onClick={closeModal} className="max-w-sm md:max-w-auto bg-white rounded p-8 shadow-md">
    <h2 className="text-lg text-gray-500 font-semibold mb-6">Add Group</h2>
    <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4"> 
        <div className="flex flex-col md:flex-row gap-4 text-sm">
                      <input  type="text" placeholder='Group' name='name'  className="  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required="required"  onChange={handleChange} />
                      {/* <span className='w-full text-sm text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2 rounded mx-0 px-1 border border-transparent transition duration-200 input-text'>Group </span></label> </div> */}
                    </div>
                    <div className="self-end">
        <button className="bg-lightgolden text-white hover:text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200">Submit</button>
        <button className="bg-active text-black font-normal m-1 py-2 px-4 rounded focus:outline-none focus:bg-slate-200 hover:bg-slate-200" onClick={onClose}>Cancel</button>
      </div>
                   </form>
                   {serverResponse && <p style={{color:'black'}}>{serverResponse.message}</p>}</div>




                   
                   
                   
                  </div>
                  </>




                  

                   
                
           
        
    )
}
export default AddMaterialGroup;